import Auth from "@/api/Auth";
import {
    CharacteristicValue,
    DayType,
    ExecutionHistory,
    HistoryItems,
    LotDocuments,
    PageOfLots,
    SessionNullable,
    SessionsController,
    SPGZ,
    StageNullable,
    StageType
} from "@/api/Sessions";
import {getCharacteristicDeclarations} from "@/api/directory";
import Participants from "@/api/Participants";
import {PageOfProducts, Product} from "@/api/Products";
import {PageOfServices} from "@/api/Services";
import {BankAccount} from "@/models/BankAccount";
import {CharacteristicDeclaration} from "@/models/ComposedLots/specifications";
import {SupplyStagePeriodType} from "@/models/enums";
import {createEffect, createEvent, createStore, sample} from "effector";
import {RelatedDataStore} from "../ComposedLot/shared/store/relatedData";
import {PageOfPurchases, Purchase} from "@/api/DirectPurchases";
import router from "@/router";
import {firstMaybe} from "@/arrayUtils";
import {
    $filters,
    changeKpgzCode,
    changePurchaseEndValidDate,
    changePurchaseStartValidDate,
    loadProducts,
    loadProductsPage,
    loadPurchases,
    loadPurchasesPage,
    loadServices,
    loadServicesPage,
    onChangeMode,
    searchProducts,
    searchPurchases,
    searchServices
} from "./filtersStore";
import {FilterConditionType, FilterValueType} from "@/api/http";
import {Participant} from "@/models";
import {toastError} from "@/components/toasts/toasts";

export enum SpecificationType {
    Products,
    Services,
    DirectPurchase
}

export declare interface SupplyStage {
    volume: string
    type: SupplyStagePeriodType
    relativePeriod: {
        startOffset: number
        startOffsetType: DayType
        endOffset: number
        endOffsetType: DayType
    } | null
    absolutePeriod: {
        startDate: Date | undefined | null
        endDate: Date | undefined | null
    } | null
    address: string
    conditions: string
}

export type ExecutionStage = {
    paymentAmount: string
    paymentDate?: Date | null
    paymentDateOffset?: number | null
    supplyStage: SupplyStage
    products: SubjectDeclarationForCreationProduct[] | null
    services: SubjectDeclarationForCreationService[] | null
    purchases: SubjectDeclarationForCreationPurchase[] | null
    showSupply: boolean
};

export type QuotationSessionExecutionStageTemplate = {
    paymentDate?: Date | null
    paymentDateOffset?: number | null
    paymentAmount: string
    supplyStage: SupplyStage
};

export type FileToUpload = {
    id: string | null
    file: {
        name: string
        length: number
        comment: string
        createdAt: string
    }
    type: string
};

export const changeAccountId = createEvent<number | null>();
export const changeLotRegNumber = createEvent<number | null>();
export const changeLaw = createEvent<string>();
export const changePurchasePerUnit = createEvent<boolean>();
export const changeIsMaster = createEvent<boolean>();
export const changeIsJoin = createEvent<boolean>();
export const changeAggregatingSessionId = createEvent<string>();
export const changeSubjectJoin = createEvent<string>();
export const changeJoinCustomersIds = createEvent<string[]>();
export const changeSubjectId = createEvent<string>();
export const changeJoinPublishDate = createEvent<Date | undefined>();
export const changeExecutionStages = createEvent<ExecutionStage[]>();
export const changeMainOrganizerId = createEvent<number>();
export const changeMaxPrice = createEvent<string>();
export const changeDeliveryByMultipleConsignees = createEvent<boolean>();
export const changeDeliveryByRecipientRequest = createEvent<boolean>();
export const changeElectronicExecution = createEvent<boolean>();
export const changeContractProvisionUsed = createEvent<boolean>();
export const changeContractProvisionPrice = createEvent<string>();
export const changeContractProvisionBankDetailId = createEvent<number>();
export const changeContractProvisionRecipient = createEvent<string>();
export const changeFio = createEvent<string>();
export const changePhone = createEvent<string>();
export const changeEmail = createEvent<string>();
export const changeDuration = createEvent<string | null>();
export const uploadLotDocuments = createEvent<FileToUpload[]>();
export const deleteLotDocuments = createEvent<number>();
export const updateLotDocumentType = createEvent<{ type: string; index: number }>();
export const changeTerm = createEvent<{type: "sendTerm" | "reviewTerm" | "paymentTerm"; value: string}>();
export const changeShowExecutionWarning = createEvent<boolean>(); // красная рамочка тут


export const toggleShowSupplyModal = createEvent();

export const loadSession = createEffect({
    name: "loadSession",
    handler: async (payload: { id: string }) => {
        return await SessionsController.getById(payload.id);
    }
});

interface CreateSessionPayload {
    services: SubjectDeclarationForCreationService[]
    products: SubjectDeclarationForCreationProduct[]
    purchases: SubjectDeclarationForCreationPurchase[]
    id: string | undefined
    lotId: string | null
    bankId: string | null
    duration: string
    status: string | null
    law: string
    fio: string
    email: string
    phone: string
    purchasePerUnit: boolean
    maxPrice: number
    isGoodsDeliveryByMultipleConsignees: boolean
    isGoodsDeliveryByRecipientRequest: boolean
    electronicExecution: boolean
    contractProvisionUsed: boolean
    contractProvisionPrice: number
    contractProvisionBankDetailId: number | null
    contractProvisionRecipient: string
    executionStages: QuotationSessionExecutionStageTemplate[]
    isJoin: boolean
    isMaster: boolean
    includedSessionsIds: string[]
    includedSessions: SessionNullable[]
    joinCustomers: Participant[]
    joinCustomersIds: string[]
    subjectId: string | null
    joinPublishDate?: Date
    subjectJoin: string | null
    mainOrganizerId: number | null
    aggregatingSessionId: string | null
    sendTerm: string | null
    paymentTerm: string | null
    reviewTerm: string | null
}

export const createOrUpdateSession = createEffect({
    name: "createOrUpdateSession",
    handler: async (payload: CreateSessionPayload) => {
        // todo const items = products | servies
        const command = {
            id: payload.id,
            lotId: payload.lotId,
            bankDetailId: payload.bankId,
            purchasePerUnit: payload.purchasePerUnit,
            maxPrice: payload.maxPrice,
            isGoodsDeliveryByMultipleConsignees: payload.isGoodsDeliveryByMultipleConsignees,
            isGoodsDeliveryByRecipientRequest: payload.isGoodsDeliveryByRecipientRequest,
            law: payload.law,
            fio: payload.fio,
            phone: payload.phone,
            email: payload.email,
            executionStages: payload.executionStages,
            durationHours: parseInt(payload.duration),
            specifications: payload.products.length > 0
                ? payload.products.map(p => ({
                    id: p.id,
                    productId: p.productId,
                    spgzId: p.subjectDeclaration.id,
                    measurementUnitId: firstMaybe(p.subjectDeclaration.measurementUnits)?.id,
                    unitPrice: p.price,
                    totalPrice: p.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0) * p.price,
                    volume: p.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0),
                    supplyStages: p.stages.map(stage =>
                    ({
                        volume: stage.volume,
                        type: p.stageType,
                        relativePeriod: { ...stage.relativePeriod },
                        absolutePeriod: { ...stage.absolutePeriod },
                        address: stage.address,
                        conditions: "string"
                    })),
                    characteristics: p.characteristics.map(c => ({ ...c, declaration: undefined, declarationId: c.declaration.id }))
                }))
                : payload.services.length > 0
                    ? payload.services.map(s =>
                    ({
                        id: s.id,
                        spgzId: s.subjectDeclaration.id,
                        measurementUnitId: firstMaybe(s.subjectDeclaration.measurementUnits)?.id,
                        unitPrice: s.price,
                        totalPrice: s.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0) * s.price,
                        volume: s.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0),
                        supplyStages: s.stages.map(stage =>
                        ({
                            volume: stage.volume,
                            type: s.stageType,
                            relativePeriod: { ...stage.relativePeriod },
                            absolutePeriod: { ...stage.absolutePeriod },
                            address: stage.address,
                            conditions: "string"
                        })),
                        characteristics: s.characteristics
                    }))
                    : payload.purchases.map(p => ({
                        id: p.id,
                        directPurchaseId: p.purchaseId,
                        spgzId: p.subjectDeclaration.id,
                        measurementUnitId: firstMaybe(p.subjectDeclaration.measurementUnits)?.id,
                        unitPrice: p.price,
                        totalPrice: p.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0) * p.price,
                        volume: p.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0),
                        supplyStages: p.stages.map(stage =>
                        ({
                            volume: stage.volume,
                            type: p.stageType,
                            relativePeriod: { ...stage.relativePeriod },
                            absolutePeriod: { ...stage.absolutePeriod },
                            address: stage.address,
                            conditions: "string"
                        })),
                        characteristics: p.characteristics.map(c => ({ ...c, declaration: undefined, declarationId: c.declaration.id }))
                    })),
            eExecution: payload.electronicExecution,
            contractProvisionUsed: payload.contractProvisionUsed,
            contractProvisionPrice: payload.contractProvisionPrice,
            contractProvisionBankDetailId: payload.contractProvisionBankDetailId,
            contractProvisionRecipient: payload.contractProvisionRecipient,
            isJoin: payload.isJoin,
            isMaster: payload.isMaster,
            includedSessionsIds: payload.includedSessionsIds,
            joinCustomersIds: !payload.id && Auth.currentUserInfo?.selectedParticipant.id ?
                [Auth.currentUserInfo.selectedParticipant.id, ...payload.joinCustomersIds].map(i => Number(i))
                : payload.joinCustomersIds.map(i => Number(i)),
            subjectId: payload.subjectId,
            joinPublishDate: payload.joinPublishDate,
            subjectJoin: payload.subjectJoin,
            mainOrganizerId: payload.mainOrganizerId,
            aggregatingSessionId: payload.aggregatingSessionId,
            status: payload.status,
            sendTerm: payload.sendTerm,
            reviewTerm: payload.reviewTerm,
            paymentTerm: payload.paymentTerm
        };

        try {
            if (command.id) {
                return { createdNew: false, session: await SessionsController.updateSession(command), joint: command.isJoin };
            } else {
                return { createdNew: true, session: await SessionsController.createSession(command), joint: command.isJoin };
            }
        } catch (e) {
            toastError({ message: "При публикации совместной котировочной сессии произошла ошибка" });
            throw e;
        }
    }
});

export const findSupplier = createEffect({
    name: "findSupplier",
    handler: async (payload: { id: string }) => {
        return await SessionsController.supplierFoundForSession(payload.id);
    }
});

export const supplierNotFound = createEffect({
    name: "supplierNotFound",
    handler: async (payload: { id: string }) => {
        return await SessionsController.supplierNotFoundForSession(payload.id);
    }
});

export const cancelSession = createEffect({
    name: "cancelSession",
    handler: async (payload: { id: string; reason: string }) => {
        return await SessionsController.cancelSession(payload.id, payload.reason);
    }
});

export const publishSession = createEffect({
    name: "publishSession",
    handler: async (payload: { id: string }) => {
        return await SessionsController.publishSession(payload.id);
    }
});

export const cancelPublicationSession = createEffect({
    name: "cancelPublicationSession",
    handler: async (payload: { id: string }) => {
        return await SessionsController.cancelPublicationSession(payload.id);
    }
});

export const recallSession = createEffect({
    name: "recallSession",
    handler: async (payload: { id: string }) => {
        return await SessionsController.recallSession(payload.id);
    }
});

export const toggleFilterOpened = createEvent();
export const toggleNameSelected = createEvent();
export const toggleShowInfo = createEvent();

export const onSelectItemService = createEvent<number | string>();
export const onSelectExistingItemService = createEvent<number | string>();
export const deleteAddedItems = createEvent<{ specType: SpecificationType; ids: (number | string)[] }>();

export const onChangePrice = createEvent<{ price: number | null; id: string }>();

export const saveStagesForSelected = createEvent<{ specType: SpecificationType; stages: StageNullable[]; ids: string[] }>();
export const onAddNewStage = createEvent<{ specType: SpecificationType; id: string }>(); // 1
export const onDeleteAllStages = createEvent<{ specType: SpecificationType; id: string }>(); // 2
export const onDeleteStage = createEvent<{ specType: SpecificationType; id: string; stageNumber: number }>(); // 3
export const onChangeShow = createEvent<{ specType: SpecificationType; id: string }>();
export const onChangeShowSpec = createEvent<number>();
export const onChangePaymentDate = createEvent<{value: Date; index: number}>();
export const onChangePaymentDateOffset = createEvent<{value: number; index: number}>();
export const onChangeVolume = createEvent<{ specType: SpecificationType; volume: number | null; id: string; stageNumber: number }>();
export const onChangeDaytype = createEvent<{ specType: SpecificationType; type: string; id: string; stageNumber: number }>();
export const onChangeSupplyBegin = createEvent<{ specType: SpecificationType; day: number | null; id: string; stageNumber: number }>();
export const onChangeSupplyEnd = createEvent<{ specType: SpecificationType; day: number | null; id: string; stageNumber: number }>();
export const onChangeAddress = createEvent<{ specType: SpecificationType; address: string; id: string; stageNumber: number }>();
export const onChangeSupplyStartDate = createEvent<{ specType: SpecificationType; day: Date; id: string; stageNumber: number }>();
export const onChangeSupplyEndDate = createEvent<{ specType: SpecificationType; day: Date; id: string; stageNumber: number }>();
export const onChangeSupplyType = createEvent<{ specType: SpecificationType; type: StageType; id: string }>();
export const resetExecutionStages = createEvent();
export const onChangeCharacteristic = createEvent<{ value: string | undefined; id: number; serviceId: string; second: boolean }>();

export const changeCancelReason = createEvent<string>();
export const changeCancelReasonOther = createEvent<string>();

export const loadLots = createEffect({
    name: "loadLots",
    handler: async (payload: { law: string }) => {
        return await SessionsController.getLots({
            from: 0,
            count: 10,
            filters: {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: Auth.selectedYear
                }]
            }
        }, payload.law);
    }
});

export const loadHistory = createEffect({
    name: "loadHistory",
    handler: async (payload: { id: string }) => {
        return await SessionsController.getHistory(payload.id);
    }
});

export const loadExecutionHistory = createEffect({
    name: "loadExecutionHistory",
    handler: async (payload: { id: string }) => {
        return await SessionsController.getExecutionHistory(payload.id);
    }
});

export const getLotDocuments = createEffect({
    name: "getLotDocuments",
    handler: async (payload: { id: string }) => {
        return await SessionsController.getLotDocuments(payload.id);
    }
});

export const updateLotDocuments = createEffect({
    name: "updateLotDocuments",
    handler: async (payload: { id: string; lotDocuments: LotDocuments }) => {
        return await SessionsController.updateLotDocuments(payload.id, payload.lotDocuments);
    }
});

export const loadBankAccounts = createEffect({
    name: "loadBankAccounts",
    handler: async (payload: {}) => {
        const user = await Auth.getUserInfoIfRequired();
        return await Participants.getAccounts(user.selectedParticipant.id);
    }
});

export const getCharacteristics = createEffect({
    name: "getCharacteristics",
    handler: async (payload: { ids: number[] }) => await Promise.all(payload.ids
        .map(async serviceId => ({ serviceId, characteristics: await getCharacteristicDeclarations(serviceId) })))
});

export interface SubjectDeclarationForCreationService {
    id: string | undefined
    idForKey: string
    stageType: StageType
    showSupply: boolean
    subjectDeclaration: SPGZ
    stages: StageNullable[]
    characteristicDeclarations: CharacteristicDeclaration[]
    characteristics: CharacteristicValue[]
    price: number
}

export interface SubjectDeclarationForCreationProduct extends SubjectDeclarationForCreationService {
    productId: string
    steId: number
    product: Product
}

export interface SubjectDeclarationForCreationPurchase extends SubjectDeclarationForCreationService {
    purchaseId: string
    purchase: Purchase
}

export type ServicesStore = {
    currentSession: SessionNullable | null
    isFilterOpened: boolean
    isSelectedName: boolean
    showInfo: boolean
    servicesPage: PageOfServices
    productsPage: PageOfProducts
    purchasesPage: PageOfPurchases
    lotsPage: PageOfLots
    history: HistoryItems
    executionHistory: ExecutionHistory
    bankAccounts: BankAccount[]
    fio: string
    email: string
    purchasePerUnit: boolean
    maxPrice: number
    isGoodsDeliveryByMultipleConsignees: boolean
    isGoodsDeliveryByRecipientRequest: boolean
    executionStages: ExecutionStage[]
    phone: string
    selectedAccount: number | null
    lotRegNumber: number | null
    law: string
    electronicExecution: boolean | null
    duration: string | null
    services: SubjectDeclarationForCreationService[]
    selectedNewIds: (number | string)[]
    selectedExistingIds: (number | string)[]
    products: SubjectDeclarationForCreationProduct[]
    purchases: SubjectDeclarationForCreationPurchase[]
    showSupplyModal: boolean
    lotDocuments: LotDocuments
    cancelReason: string | null
    cancelReasonOther: string | null
    purchaseStartValidDate: Date
    purchaseEndValidDate: Date
    contractProvisionUsed: boolean
    contractProvisionPrice: string
    contractProvisionBankDetailId: number
    contractProvisionRecipient: string
    sessionSubjectName: string
    isJoin: boolean
    isMaster: boolean
    includedSessionsIds: string[]
    includedSessions: SessionNullable[]
    subjectJoin: string
    joinCustomers: Participant[]
    joinCustomersIds: string[]
    subjectId: string
    subject: {id: string; name: string; type: string}
    joinPublishDate?: Date
    mainOrganizerId: number | null
    aggregatingSessionId: string | null
    sendTerm: string
    paymentTerm: string
    reviewTerm: string
    showExecutionStageWarning: boolean
};



export const defaultStage: StageNullable = {
    address: "Большая Полянка улица, дом 41, строение 1-2, город Москва, почтовый индекс 119180",
    volume: "1",
    type: "RELATIVE",
    conditions: 'string',
    relativePeriod: {
        startOffset: 0, startOffsetType: "NORMAL", endOffset: 0,
        endOffsetType: "NORMAL"
    },
    absolutePeriod: {
        startDate: new Date(),
        endDate: new Date()
    }
};

export const emptyPage = {
    totalCount: 0,
    from: 0,
    to: 0,
    items: []
};

export const createServicesStore = (isJoint?: boolean) => {
    const store =  createStore<ServicesStore>({
        currentSession: null,
        showSupplyModal: false,
        lotsPage: emptyPage,
        history: [],
        executionHistory: [],
        servicesPage: emptyPage,
        productsPage: emptyPage,
        purchasesPage: emptyPage,
        fio: "Иванов Иван Иванович",
        phone: "+7(495)123-45-67 (1)",
        email: "no-reply@mos.ru",
        services: [],
        selectedNewIds: [],
        selectedExistingIds: [],
        products: [],
        purchases: [],
        bankAccounts: [],
        selectedAccount: null,
        lotRegNumber: null,
        law: 'F44',
        electronicExecution: null,
        duration: '24',
        isFilterOpened: false,
        isSelectedName: true,
        showInfo: false,
        lotDocuments: [],
        purchaseStartValidDate: new Date(),
        purchaseEndValidDate: new Date(),
        cancelReason: null,
        cancelReasonOther: null,
        contractProvisionUsed: false,
        contractProvisionPrice: '',
        contractProvisionBankDetailId: 0,
        contractProvisionRecipient: '',
        sessionSubjectName: '',
        purchasePerUnit: false,
        maxPrice: 0,
        isGoodsDeliveryByMultipleConsignees: false,
        isGoodsDeliveryByRecipientRequest: false,
        executionStages: [],
        isJoin: !!isJoint,
        isMaster: !!isJoint,
        includedSessionsIds: [],
        includedSessions: [],
        subjectJoin: "",
        joinCustomers: [],
        joinCustomersIds: [],
        subjectId: "",
        joinPublishDate: undefined,
        mainOrganizerId: null,
        aggregatingSessionId: "",
        sendTerm: "5",
        paymentTerm: "5",
        reviewTerm: "5",
        subject: {id: "", name: "", type: ""},
        showExecutionStageWarning: false
    })
        .on(loadSession.done, (state, {result}) => {
            const lotRegNumber = result.lot?.regNumber ?? 0;
            const selectedAccount = result.bankDetail?.id ?? 0;
            const products: SubjectDeclarationForCreationProduct[] = result.specifications
                .filter(x => x.product !== undefined && x.product !== null)
                .map(s => {
                    return {
                        stageType: firstMaybe(s.supplyStages)?.type || 'RELATIVE',
                        showSupply: false,
                        id: s.id,
                        idForKey: s.id!,
                        productId: s.product!.id,
                        steId: s.product!.steId || 0,
                        subjectDeclaration: s.spgz as SPGZ,
                        product: s.product!,
                        stages: s.supplyStages,
                        price: s.unitPrice,
                        characteristics: s.characteristics,
                        characteristicDeclarations: []
                    };
                });
            const services: SubjectDeclarationForCreationService[] = result.specifications
                .filter(x => (x.product === null || x.product === undefined) && (x.directPurchase === null || x.directPurchase === undefined))
                .map(s => {
                    return {
                        stageType: firstMaybe(s.supplyStages)?.type || 'RELATIVE',
                        showSupply: false,
                        id: s.id,
                        idForKey: s.id?.toString()!,
                        subjectDeclaration: s.spgz as SPGZ,
                        stages: s.supplyStages,
                        price: s.unitPrice,
                        characteristics: s.characteristics,
                        characteristicDeclarations: []
                    };
                });
            const purchases: SubjectDeclarationForCreationPurchase[] = result.specifications
                .filter(x => x.directPurchase !== undefined && x.directPurchase !== null)
                .map(s => {
                    return {
                        stageType: firstMaybe(s.supplyStages)?.type || 'RELATIVE',
                        showSupply: false,
                        id: s.id,
                        idForKey: s.id!,
                        purchaseId: s.directPurchase!.id,
                        subjectDeclaration: s.spgz as SPGZ,
                        purchase: s.directPurchase!,
                        stages: s.supplyStages,
                        price: s.unitPrice,
                        characteristics: s.characteristics,
                        characteristicDeclarations: []
                    };
                });

            if (products.length) {
                onChangeMode('PRODUCTS');
                changeKpgzCode({kpgzCode: firstMaybe(products)?.subjectDeclaration.subjectClass.code.slice(0, 5) || null});
                loadProductsPage({page: 0});
            }
            if (services.length) {
                onChangeMode('SERVICES');
                changeKpgzCode({kpgzCode: firstMaybe(services)?.subjectDeclaration.subjectClass.code.slice(0, 5) || null});
                loadServicesPage({page: 0});
            }
            if (purchases.length) {
                onChangeMode('PURCHASES');
                changeKpgzCode({kpgzCode: firstMaybe(purchases)?.subjectDeclaration.subjectClass.code.slice(0, 5) || null});
                loadPurchasesPage({page: 0});
            }

            const electronicExecution = result.eexecution;
            const contractProvisionUsed = result.contractProvisionUsed;
            const contractProvisionRecipient = result.contractProvisionRecipient ?? '';
            const contractProvisionPrice = result.contractProvisionPrice ? parseInt(result.contractProvisionPrice).toFixed(0).toString() : '0';
            const maxPrice = result.maxPrice ? +parseFloat(result.maxPrice).toFixed(2) : 0;
            const contractProvisionBankDetailId = result.contractProvisionBankDetail?.id ?? 0;
            const sessionSubjectName = (result.specifications.length > 1 && result.specifications[0]?.spgz?.subjectClass.parents[1]?.name) || '';
            const joinCustomers: Participant[] = result.joinCustomers as Participant[] ?? [];
            const joinCustomersIds = result.joinCustomers.map(i => i.id!.toString());
            const includedSessionIds = result.includedSessions.map(s => s.id) ?? [];
            const executionStages: ExecutionStage[] = result.executionStages.map(item => ({
                paymentDate: item.paymentDate,
                paymentDateOffset: item.paymentDateOffset,
                paymentAmount: item.paymentAmount,
                supplyStage: item.supplyStage,
                services: item.specifications
                    .filter(x => (x.product === null || x.product === undefined) && (x.directPurchase === null || x.directPurchase === undefined))
                    .map(s => {
                        return {
                            stageType: firstMaybe(s.supplyStages)?.type || 'RELATIVE',
                            showSupply: false,
                            id: s.id,
                            idForKey: s.id?.toString()!,
                            subjectDeclaration: s.spgz as SPGZ,
                            stages: s.supplyStages,
                            price: s.unitPrice,
                            characteristics: s.characteristics,
                            characteristicDeclarations: []
                        };
                    }),
                products: item.specifications.filter(x => x.product !== undefined && x.product !== null)
                    .map(s => {
                        return {
                            stageType: firstMaybe(s.supplyStages)?.type || 'RELATIVE',
                            showSupply: false,
                            id: s.id,
                            idForKey: s.id!,
                            productId: s.product!.id,
                            steId: s.product!.steId || 0,
                            subjectDeclaration: s.spgz as SPGZ,
                            product: s.product!,
                            stages: s.supplyStages,
                            price: s.unitPrice,
                            characteristics: s.characteristics,
                            characteristicDeclarations: []
                        };
                    }),
                purchases: item.specifications
                    .filter(x => x.directPurchase !== undefined && x.directPurchase !== null)
                    .map(s => {
                        return {
                            stageType: firstMaybe(s.supplyStages)?.type || 'RELATIVE',
                            showSupply: false,
                            id: s.id,
                            idForKey: s.id!,
                            purchaseId: s.directPurchase!.id,
                            subjectDeclaration: s.spgz as SPGZ,
                            purchase: s.directPurchase!,
                            stages: s.supplyStages,
                            price: s.unitPrice,
                            characteristics: s.characteristics,
                            characteristicDeclarations: []
                        };
                    }),
                showSupply: false
            }));
            const subject = {...result.subject, id: result.subject.id.toString()};

            return {
                ...state,
                sessionSubjectName,
                fio: result.fio,
                purchasePerUnit: result.purchasePerUnit ?? false,
                phone: result.phone,
                maxPrice,
                email: result.email,
                currentSession: result,
                lotRegNumber,
                selectedAccount,
                services,
                products,
                purchases,
                electronicExecution,
                contractProvisionUsed,
                contractProvisionBankDetailId,
                contractProvisionRecipient,
                contractProvisionPrice,
                law: result.law,
                duration: result.durationHours.toString(),
                executionStages: executionStages,
                isJoin: !!result.isJoin,
                isMaster: !!result.isMaster,
                includedSessionsIds: includedSessionIds,
                includedSessions: result.includedSessions,
                subjectJoin: result.subjectJoin ?? "Объект закупки",
                joinCustomers: joinCustomers ?? [],
                joinCustomersIds: joinCustomersIds,
                subjectId: result.subject.id.toString(),
                joinPublishDate: result.joinPublishDate,
                aggregatingSessionId: result.aggregatingSessionId ?? null,
                isGoodsDeliveryByMultipleConsignees: result.isGoodsDeliveryByMultipleConsignees,
                isGoodsDeliveryByRecipientRequest: result.isGoodsDeliveryByRecipientRequest,
                sendTerm: result.sendTerm ?? "",
                paymentTerm: result.paymentTerm ?? "",
                reviewTerm: result.reviewTerm ?? "",
                subject: subject
            };
        })
        .on(createOrUpdateSession.done, (state, {result}) => {
            if (result.createdNew) {
                if (result.joint) {
                    router.push(`/shop/joint-sessions/${result.session.id}`);
                } else {
                    router.push(`/shop/sessions/${result.session.id}`);
                }
            }
            return {...state, currentSession: result.session};
        })
        .on(findSupplier.done, (state, {result}) => ({...state, currentSession: result}))
        .on(supplierNotFound.done, (state, {result}) => ({...state, currentSession: result}))
        .on(cancelSession.done, (state, {result}) => ({...state, currentSession: result}))
        .on(publishSession.done, (state, {result}) => ({...state, currentSession: result}))
        .on(changeLaw, (state, law) => {
            if (law !== state.law && law) {
                loadLots({law});
                if (law === 'F44') {
                    return ({...state, law: law, electronicExecution: true, lotRegNumber: null});
                }
                return ({...state, law: law || '', lotRegNumber: null});
            }
        })
        .on(cancelPublicationSession.done, (state, {result}) => ({...state, currentSession: result}))
        .on(recallSession.done, (state, {result}) => ({...state, currentSession: result}))
        .on(changePurchasePerUnit, (state, purchasePerUnit) => ({...state, purchasePerUnit}))
        .on(changeMaxPrice, (state, value) => {
            const trimmedStr = value.trim();
            if(!trimmedStr.length) return state;
    
            const cleanedStr = value.replaceAll(/\s/g, "");
            if(/^\d+[,.]?(\d+)?$/.test(cleanedStr)) {
                if(Array.from(cleanedStr.matchAll(/[,.]/g)).length > 1) return state;
                const price = parseFloat(cleanedStr.replace(",", "."));
                if(!isNaN(price)) {
                    return {...state, maxPrice: parseFloat(price.toFixed(2))};
                }
            }
            return state;
        })
        .on(changeDeliveryByMultipleConsignees, (state, isGoodsDeliveryByMultipleConsignees) => ({
            ...state,
            isGoodsDeliveryByMultipleConsignees
        }))
        .on(changeDeliveryByRecipientRequest, (state, isGoodsDeliveryByRecipientRequest) => ({
            ...state,
            isGoodsDeliveryByRecipientRequest
        }))
        .on(changeElectronicExecution, (state, electronicExecution) => ({...state, electronicExecution}))
        .on(changeContractProvisionUsed, (state, contractProvisionUsed) => {
            if (contractProvisionUsed) {
                const filtersState = $filters.getState();

                const initialPrice = getInitialPrice(filtersState.mode === 'SERVICES' ? state.services : state.purchases);

                if (initialPrice >= 100000) {
                    return {
                        ...state,
                        contractProvisionUsed,
                        contractProvisionPrice: getContractProvisionPrice(initialPrice).toString()
                    };
                }
            }

            return {...state, contractProvisionUsed, contractProvisionPrice: '0'};
        })
        .on(changeContractProvisionPrice, (state, contractProvisionPrice) => ({...state, contractProvisionPrice}))
        .on(changeContractProvisionBankDetailId, (state, contractProvisionBankDetailId) => ({
            ...state,
            contractProvisionBankDetailId
        }))
        .on(changeContractProvisionRecipient, (state, contractProvisionRecipient) => ({
            ...state,
            contractProvisionRecipient
        }))
        .on(changeFio, (state, fio) => ({...state, fio}))
        .on(changePhone, (state, phone) => ({...state, phone}))
        .on(changeEmail, (state, email) => ({...state, email}))
        .on(changeDuration, (state, duration) => ({...state, duration}))
        .on(changeAccountId, (state, selectedAccount) => ({...state, selectedAccount}))
        .on(getLotDocuments.done, (state, {result}) => ({...state, lotDocuments: result}))
        .on(changeLotRegNumber, (state, regNumber) => {
            const lot = state.lotsPage.items.find(i => i.regNumber.toString() === regNumber?.toString());
            if (lot && lot.basicInfo.specialPurchaseType === 'SMALL_VOLUME_SIMPLE') {
                return ({...state, lotRegNumber: regNumber, electronicExecution: true});
            }

            return ({...state, lotRegNumber: regNumber});
        })
        .on(updateLotDocuments.done, (state, {result}) => ({...state, lotDocuments: result}))
        .on(uploadLotDocuments, (state, files) => {
            // const uploadedDocuments = [];
            // for (let i = 0; i < files.length; i++) {
            //     const file = files[i];
            //     if (file.file.length <= 10485760) {
            //         uploadedDocuments.push(file);
            //     } else {
            //         alert(`Размер файла "${file.file.name}" превышает 10 Мб`);
            //     }
            // }

            const uploadedDocuments = files.filter(file => {
                const isFileBig = file.file.length > 10485760;

                if (isFileBig) {
                    alert(`Размер файла "${file.file.name}" превышает 10 Мб`);

                    return false;
                }

                return true;
            });

            return {...state, lotDocuments: [...state.lotDocuments, ...uploadedDocuments]};
        })
        .on(deleteLotDocuments, (state, index) => {
            const {lotDocuments} = state;
            lotDocuments.splice(index, 1);
            return {...state, lotDocuments};
        })
        .on(updateLotDocumentType, (state, data) => {
            const {lotDocuments} = state;
            const {type, index} = data;
            lotDocuments[index] = {...lotDocuments[index], type};

            return {...state, lotDocuments: lotDocuments};
        })
        .on(loadLots.done, (state, {result}) => ({...state, lotsPage: result}))
        .on(loadHistory.done, (state, {result}) => ({...state, history: result}))
        .on(loadExecutionHistory.done, (state, {result}) => ({...state, executionHistory: result}))
        .on(loadBankAccounts.done, (state, {result}) => ({...state, bankAccounts: result}))
        .on(loadServicesPage.done, (state, {result}) => ({...state, servicesPage: result}))
        .on(loadProductsPage.done, (state, {result}) => ({...state, productsPage: result}))
        .on(loadPurchasesPage.done, (state, {result}) => ({...state, purchasesPage: result}))
        .on(getCharacteristics.done, (state, {result}) => {
            const filteredCharacterictics = result.map(c => ({
                ...c,
                characteristics: c.characteristics.filter(c => !(c.conditionType !== 'ENUM' && c.valueType == 'TEXT'))
            }));
            if (state.services.length > 0) {
                const services = state.services.map(s => {
                    const characteristics = filteredCharacterictics.find(r => r.serviceId.toString() === s.idForKey);
                    const characteristicsValues = characteristics?.characteristics.map(c => {
                        const enumType = !!c.enumValues.length;
                        return {
                            declaration: {id: c.id},
                            enumValues: enumType ? [c.enumValues[0]] : [],
                            conditionType: c.conditionType,
                            subConditionTypeFirst: c.conditionType,
                            subConditionTypeSecond: c.conditionType,
                            numberValueFirst: enumType
                                ? undefined
                                : c.defaultFirst,
                            numberValueSecond: undefined
                        };
                    });
                    return ({
                        ...s,
                        characteristicDeclarations: characteristics?.characteristics || [],
                        characteristics: characteristicsValues || []
                    });
                });
                return {...state, services};
            }

            if (state.products.length > 0) {
                const products = state.products.map(s => {
                    const characteristics = filteredCharacterictics.find(r => r.serviceId.toString() === s.idForKey);
                    const characteristicsValues = characteristics?.characteristics.map(c => {
                        const enumType = !!c.enumValues.length;
                        return {
                            declaration: {id: c.id},
                            enumValues: enumType ? [c.enumValues[0]] : [],
                            conditionType: c.conditionType,
                            subConditionTypeFirst: c.conditionType,
                            subConditionTypeSecond: c.conditionType,
                            numberValueFirst: enumType
                                ? undefined
                                : c.defaultFirst,
                            numberValueSecond: undefined
                        };
                    });
                    return ({
                        ...s,
                        characteristicDeclarations: characteristics?.characteristics || [],
                        characteristics: characteristicsValues || []
                    });
                });
                return {...state, products};
            }

            if (state.purchases.length > 0) {
                const purchases = state.purchases.map(s => {
                    const characteristics = filteredCharacterictics.find(r => r.serviceId.toString() === s.idForKey);
                    const characteristicsValues = characteristics?.characteristics.map(c => {
                        const enumType = !!c.enumValues.length;
                        return {
                            declaration: {id: c.id},
                            enumValues: enumType ? [c.enumValues[0]] : [],
                            conditionType: c.conditionType,
                            subConditionTypeFirst: c.conditionType,
                            subConditionTypeSecond: c.conditionType,
                            numberValueFirst: enumType
                                ? undefined
                                : c.defaultFirst,
                            numberValueSecond: undefined
                        };
                    });
                    return ({
                        ...s,
                        characteristicDeclarations: characteristics?.characteristics || [],
                        characteristics: characteristicsValues || []
                    });
                });
                return {...state, purchases};
            }
        })
        .on(loadServices.done, (state, {result}) => {
            getCharacteristics({ids: result.map(r => r.id)});
            return ({
                ...state,
                sessionSubjectName: result[0]?.subjectClass.parents[1]?.name || '',
                services: state.services.concat(result.map(s => ({
                    stageType: 'RELATIVE',
                    id: undefined,
                    idForKey: s.id.toString(),
                    showSupply: false,
                    subjectDeclaration: s,
                    stages: [],
                    characteristicDeclarations: [],
                    characteristics: [],
                    price: 0
                })))
            });
        })
        .on(loadProducts.done, (state, {result}) => {
            getCharacteristics({ids: result.map(r => r.spgz.id)});
            return ({
                ...state,
                sessionSubjectName: result[0]?.spgz.subjectClass.parents[1]?.name || '',
                products: state.products.concat(result.map(s => ({
                    stageType: 'RELATIVE',
                    showSupply: false,
                    id: undefined,
                    idForKey: s.id,
                    productId: s.id,
                    steId: s.steId || 0,
                    subjectDeclaration: s.spgz,
                    product: s,
                    stages: [],
                    characteristicDeclarations: [],
                    characteristics: [],
                    price: s.referentPrice ? parseFloat(s.referentPrice) : 0
                })))
            });
        })
        .on(loadPurchases.done, (state, {result}) => {
            getCharacteristics({ids: result.map(r => r.spgz.id)});
            return ({
                ...state,
                sessionSubjectName: result[0]?.spgz.subjectClass.parents[1]?.name || '',
                purchases: state.purchases.concat(result.map(s => ({
                    stageType: 'RELATIVE',
                    showSupply: false,
                    id: undefined,
                    idForKey: s.id,
                    purchaseId: s.id,
                    subjectDeclaration: s.spgz,
                    purchase: s,
                    stages: [],
                    characteristicDeclarations: [],
                    characteristics: [],
                    price: s.priceWithNds
                })))
            });
        })
        .on(onAddNewStage, (state, payload) => {
            const addStage = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                items[itemIndex] = {...items[itemIndex], stages: items[itemIndex].stages.concat([defaultStage])};
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: addStage(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: addStage(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: addStage(state.purchases)};
            }
        })
        .on(onDeleteAllStages, (state, payload) => {
            const deleteAllStages = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                items[itemIndex].stages = [];
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: deleteAllStages(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: deleteAllStages(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: deleteAllStages(state.purchases)};
            }
        })
        .on(onDeleteStage, (state, payload) => {
            const deleteStage = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                items[itemIndex].stages.splice(payload.stageNumber, 1);
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: deleteStage(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: deleteStage(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: deleteStage(state.purchases)};
            }
        })
        .on(onChangeShow, (state, payload) => {
            const changeShow = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                items[itemIndex] = {...items[itemIndex], showSupply: !items[itemIndex].showSupply};
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeShow(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeShow(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeShow(state.purchases)};
            }
        })
        .on(onChangeShowSpec, (state, payload) => {
            const stagesCopy = state.executionStages;
            stagesCopy[payload] = {...stagesCopy[payload], showSupply: !stagesCopy[payload].showSupply};

            return {...state, executionStages: stagesCopy};
        })
        .on(onChangePaymentDate, (state, payload) => {
            const stagesCopy = state.executionStages;
            stagesCopy[payload.index] = {...stagesCopy[payload.index], paymentDate: payload.value};
            return {...state, executionStages: stagesCopy};
        })
        .on(onChangePaymentDateOffset, (state, payload) => {
            const stagesCopy = state.executionStages;
            stagesCopy[payload.index] = {...stagesCopy[payload.index], paymentDateOffset: payload.value};
            return {...state, executionStages: stagesCopy};
        })
        .on(onChangePrice, (state, payload) => {
            if(payload.price === null) return state;
            const {services, products, purchases} = state;
            let currentType: "services" | "products" | "purchases" | null = null;
            const serviceIndex = services.findIndex(s => s.idForKey === payload.id);
            const productIndex = products.findIndex(s => s.idForKey === payload.id);
            const purchaseIndex = purchases.findIndex(s => s.idForKey === payload.id);
            if(serviceIndex !== -1) {
                currentType = "services";
                const service = services[serviceIndex];
                services[serviceIndex] = {...service, price: payload.price};
            } else if(productIndex !== -1) {
                currentType = "products";
                const product = products[productIndex];
                products[productIndex] = {...product, price: payload.price};
            } else if(purchaseIndex !== -1) {
                currentType = "purchases";
                const purchase = purchases[purchaseIndex];
                purchases[purchaseIndex] = {...purchase, price: payload.price};
            }
            
            if(currentType === null) {
                console.warn(`Unable to find item for price change with id ${payload.id}`);
                return state;
            }

            if (state.contractProvisionUsed) {
                const initialPrice = getInitialPrice(currentType === "services" ? services : currentType === "products" ? products : purchases);

                if (initialPrice >= 100000) {
                    return {...state, contractProvisionPrice: getContractProvisionPrice(initialPrice).toString()};
                }
            }
            const updatedItems = currentType === "services" ? {services} : currentType === "products" ? {products} : {purchases};
            return {...state, ...updatedItems, contractProvisionUsed: false, contractProvisionPrice: '0'};
        })
        .on(onChangeVolume, (state, payload) => {
            const volume = payload.volume?.toString() || null;
            const changeVolume = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                const foundStage = items[itemIndex].stages[payload.stageNumber];
                if (foundStage) {
                    items[itemIndex].stages[payload.stageNumber] = {...foundStage, volume};
                }
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                const services = changeVolume(state.services);
                if (state.contractProvisionUsed) {
                    const initialPrice = getInitialPrice(services);

                    if (initialPrice >= 100000) {
                        return {...state, contractProvisionPrice: getContractProvisionPrice(initialPrice).toString()};
                    }
                }

                return {...state, services, contractProvisionUsed: false, contractProvisionPrice: '0'};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeVolume(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                const purchases = changeVolume(state.purchases);
                const code = (firstMaybe(state.purchases)?.subjectDeclaration.subjectClass.code.slice(0, 2));
                const isServiceType = code === '03' || code === '02';
                if (isServiceType) {
                    if (state.contractProvisionUsed) {
                        const initialPrice = getInitialPrice(purchases);

                        if (initialPrice >= 100000) {
                            return {
                                ...state,
                                contractProvisionPrice: getContractProvisionPrice(initialPrice).toString()
                            };
                        }
                    }
                }

                return {...state, purchases: changeVolume(state.purchases)};
            }
        })
        .on(onChangeCharacteristic, (state, payload) => {
            const {services} = state;
            const serviceIndex = services.findIndex(s => s.idForKey === payload.serviceId.toString());
            const service = services[serviceIndex];

            const characteristicIndex = service.characteristics.findIndex(s => s.declaration.id.toString() === payload.id.toString());
            const characteristic = service.characteristics[characteristicIndex];

            const enumType = !!characteristic.enumValues.length;
            service.characteristics[characteristicIndex] = {
                ...characteristic,
                enumValues: enumType && payload.value ? [payload.value] : [],
                numberValueFirst: enumType
                    ? undefined
                    : payload.second ? characteristic.numberValueFirst : payload.value,
                numberValueSecond: enumType
                    ? undefined
                    : payload.second ? payload.value : characteristic.numberValueSecond
            };

            services[serviceIndex] = service;

            return {...state, services};
        })
        .on(onChangeDaytype, (state, payload) => {
            const changeDaytype = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                const foundStage = items[itemIndex].stages[payload.stageNumber];
                if (foundStage && foundStage.relativePeriod) {
                    const newOffsetType = payload.type as DayType;
                    items[itemIndex].stages[payload.stageNumber] = {
                        ...foundStage,
                        relativePeriod: {
                            ...foundStage.relativePeriod,
                            endOffsetType: newOffsetType,
                            startOffsetType: newOffsetType
                        }
                    };
                }
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeDaytype(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeDaytype(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeDaytype(state.purchases)};
            }
        })
        .on(onChangeSupplyBegin, (state, payload) => {
            const changeSupplyBegin = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                const foundStage = items[itemIndex].stages[payload.stageNumber];
                if (foundStage && foundStage.relativePeriod) {
                    items[itemIndex].stages[payload.stageNumber] = {
                        ...foundStage,
                        relativePeriod: {...foundStage.relativePeriod, startOffset: payload.day}
                    };
                }
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeSupplyBegin(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeSupplyBegin(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeSupplyBegin(state.purchases)};
            }
        })
        .on(onChangeSupplyEnd, (state, payload) => {
            const changeSupplyEnd = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                const foundStage = items[itemIndex].stages[payload.stageNumber];
                if (foundStage && foundStage.type === 'RELATIVE' && foundStage.relativePeriod) {
                    items[itemIndex].stages[payload.stageNumber] = {
                        ...foundStage,
                        relativePeriod: {...foundStage.relativePeriod, endOffset: payload.day}
                    };
                }
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeSupplyEnd(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeSupplyEnd(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeSupplyEnd(state.purchases)};
            }
        })
        .on(onChangeAddress, (state, payload) => {
            const changeAddress = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                const foundStage = items[itemIndex].stages[payload.stageNumber];
                if (foundStage && foundStage.type === 'RELATIVE' && foundStage.absolutePeriod) {
                    items[itemIndex].stages[payload.stageNumber] = {...foundStage, address: payload.address};
                }
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeAddress(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeAddress(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeAddress(state.purchases)};
            }
        })
        .on(onChangeSupplyStartDate, (state, payload) => {
            const changeStartDate = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                const foundStage = items[itemIndex].stages[payload.stageNumber];
                if (foundStage && foundStage.absolutePeriod) {
                    items[itemIndex].stages[payload.stageNumber] = {
                        ...foundStage,
                        absolutePeriod: {...foundStage.absolutePeriod, startDate: payload.day}
                    };
                }
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeStartDate(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeStartDate(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeStartDate(state.purchases)};
            }
        })
        .on(onChangeSupplyEndDate, (state, payload) => {
            const changeEndDate = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const itemIndex = items.findIndex(s => s.idForKey === payload.id);
                const foundStage = items[itemIndex].stages[payload.stageNumber];
                if (foundStage && foundStage.absolutePeriod) {
                    items[itemIndex].stages[payload.stageNumber] = {
                        ...foundStage,
                        absolutePeriod: {...foundStage.absolutePeriod, endDate: payload.day}
                    };
                }
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeEndDate(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeEndDate(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeEndDate(state.purchases)};
            }
        })
        .on(onChangeSupplyType, (state, payload) => {
            const changeSupplyType = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                const serviceIndex = items.findIndex(s => s.idForKey === payload.id);
                const service = items[serviceIndex];
                service.stages.map(stage => ({...stage, type: payload.type}));
                service.stageType = payload.type;
                items[serviceIndex] = service;
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: changeSupplyType(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: changeSupplyType(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: changeSupplyType(state.purchases)};
            }
        })
        .on(searchServices.done, (state, {result}) => ({...state, servicesPage: result}))
        .on(searchProducts.done, (state, {result}) => ({...state, productsPage: result}))
        .on(searchPurchases.done, (state, {result}) => ({...state, purchasesPage: result}))
        .on(onSelectItemService, (state, id) => {
            const index = state.selectedNewIds.indexOf(id);
            if (index === -1) {
                state.selectedNewIds.push(id);
            } else {
                state.selectedNewIds.splice(index, 1);
            }
            return {...state, selectedNewIds: state.selectedNewIds};
        })
        .on(onSelectExistingItemService, (state, id) => {
            const index = state.selectedExistingIds.indexOf(id);
            if (index === -1) {
                state.selectedExistingIds.push(id);
            } else {
                state.selectedExistingIds.splice(index, 1);
            }
            return {...state, selectedExistingIds: state.selectedExistingIds};
        })
        .on(deleteAddedItems, (state, payload) => {
            if (payload.specType === SpecificationType.Services) {
                const {services} = state;
                payload.ids.forEach(id => {
                    const index = services.findIndex(service => service.idForKey === id.toString());
                    if (index !== -1) {
                        services.splice(index, 1);
                    }
                });

                if (state.contractProvisionUsed) {
                    const initialPrice = getInitialPrice(services);

                    if (initialPrice >= 100000) {
                        return {...state, contractProvisionPrice: getContractProvisionPrice(initialPrice).toString()};
                    }
                }

                return {...state, services, contractProvisionUsed: false, contractProvisionPrice: '0'};
            }
            if (payload.specType === SpecificationType.Products) {
                const {products} = state;
                payload.ids.forEach(id => {
                    const index = products.findIndex(product => product.idForKey === id.toString());
                    if (index !== -1) {
                        products.splice(index, 1);
                    }
                });
                return {...state, products};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                const {purchases} = state;
                payload.ids.forEach(id => {
                    const index = purchases.findIndex(purchase => purchase.idForKey === id.toString());
                    if (index !== -1) {
                        purchases.splice(index, 1);
                    }
                });
                return {...state, purchases};
            }
        })
        .on(toggleShowSupplyModal, (state) => ({...state, showSupplyModal: !state.showSupplyModal}))
        .on(toggleFilterOpened, state => ({...state, isFilterOpened: !state.isFilterOpened}))
        .on(toggleShowInfo, state => ({...state, showInfo: !state.showInfo}))
        .on(toggleNameSelected, state => ({...state, isSelectedName: !state.isSelectedName}))
        .on(saveStagesForSelected, (state, payload) => {
            const saveStages = <T extends SubjectDeclarationForCreationService>(items: T[]) => {
                payload.ids.forEach(id => {
                    const index = items.findIndex(s => s.idForKey === id.toString());
                    const newStagesFixed = payload.stages.map((_, i) => ({
                        ...payload.stages[i]
                    }));
                    items[index].stages = [...newStagesFixed];
                    items[index].stageType = payload.stages[0].type;
                });
                return items;
            };

            if (payload.specType === SpecificationType.Services) {
                return {...state, services: saveStages(state.services)};
            }
            if (payload.specType === SpecificationType.Products) {
                return {...state, products: saveStages(state.products)};
            }
            if (payload.specType === SpecificationType.DirectPurchase) {
                return {...state, purchases: saveStages(state.purchases)};
            }
        })
        .on(changePurchaseStartValidDate, (state, purchaseStartValidDate) => ({...state, purchaseStartValidDate}))
        .on(changePurchaseEndValidDate, (state, purchaseEndValidDate) => ({...state, purchaseEndValidDate}))
        .on(changeCancelReason, (state, cancelReason) => ({...state, cancelReason}))
        .on(changeCancelReasonOther, (state, cancelReasonOther) => ({...state, cancelReasonOther}))
        .on(changeAggregatingSessionId, (state, payload) => ({...state, aggregatingSessionId: payload}))
        .on(changeIsJoin, (state, payload) => ({...state, isJoin: payload}))
        .on(changeIsMaster, (state, payload) => ({...state, isMaster: payload}))
        .on(changeSubjectJoin, (state, payload) => ({...state, subjectJoin: payload}))
        .on(changeJoinCustomersIds, (state, payload) => ({...state, joinCustomersIds: payload}))
        .on(changeSubjectId, (state, payload) => ({...state, subjectId: payload}))
        .on(changeJoinPublishDate, (state, payload) => ({...state, joinPublishDate: payload}))
        .on(changeExecutionStages, (state, payload) => ({...state, executionStages: payload}))
        .on(changeMainOrganizerId, (state, payload) => ({...state, mainOrganizerId: payload}))
        .on(resetExecutionStages, (state, payload) => ({...state, executionStages: []}))
        .on(changeTerm, (state, payload) => ({...state, [payload.type]: payload.value}))
        .on(changeShowExecutionWarning, (state, payload) => ({...state, showExecutionStageWarning: payload}));

    sample({
        source: onAddNewStage,
        fn: () => {
            return true;
        },
        target: changeShowExecutionWarning
    });

    sample({
        source: onDeleteAllStages,
        fn: () => {
            return true;
        },
        target: changeShowExecutionWarning
    });

    sample({
        source: onDeleteStage,
        fn: () => {
            return true;
        },
        target: changeShowExecutionWarning
    });


    return store;

};

export const $relatedData = RelatedDataStore.createStore();

const getInitialPrice = (services: SubjectDeclarationForCreationService[]) => {
    return services.reduce((acc, item) => {
        const volume = item.stages.reduce((acc, stage) => acc + parseInt(stage.volume || '0'), 0);
        return acc + item.price * volume;
    }, 0);
};

export const getWinningSupplier = (session: SessionNullable) => {
    return session.suppliers.find(x => x.status === 'WINNER')?.participant.shortName ?? null;
};

export const getType = (store: ServicesStore) => {
    return store.services.length > 0
        ? SpecificationType.Services
        : store.products.length > 0
            ? SpecificationType.Products
            : SpecificationType.DirectPurchase;
};

const getContractProvisionPrice = (value: number) => {
    if (value >= 100000 && value < 200000) {
        return 10000;
    }
    if (value >= 200000 && value < 300000) {
        return 20000;
    }
    if (value >= 300000 && value < 600000) {
        return 30000;
    }
    if (value >= 600000 && value < 1000000) {
        return 50000;
    }
    if (value >= 1000000) {
        return 100000;
    }
    return 0;
};
