import { IJsonFormattable } from "@/models/IJsonFormattable";
import Decimal from "decimal.js";
import { SupplyStagePeriodType } from "@/models/enums/SupplyStagePeriodType";
import { DayType } from "@/models/enums/DayType";
import { IDtoContractSpecificationSupplyStage } from '../json/Contracts/IDtoContractSpecificationSupplyStage';
import {Address} from "@/models";

export class ContractSpecificationSupplyStage implements IJsonFormattable<IDtoContractSpecificationSupplyStage> {
    constructor(
        public id: string,
        public specificationId: string,
        public volume: Decimal,
        public type: SupplyStagePeriodType,
        public relativePeriod: {
            startOffset: number | null
            startOffsetType: DayType
            endOffset: number | null
            endOffsetType: DayType
            finishYear: number | null
        },
        public absolutePeriod: {
            startDate: Date | null
            endDate: Date | null
            fromContractConclusion: boolean
        },
        public conditions: string,
        public address: Address | null
    ) {}

    public toJson(): IDtoContractSpecificationSupplyStage {
        return {
            ...this,
            volume: this.volume.toString(),
            relativePeriod: {
                ...this.relativePeriod,
                startOffset: this.relativePeriod.startOffset || 0,
                endOffset: this.relativePeriod.endOffset || 0,
                finishYear: this.relativePeriod.finishYear || 0,
            },
            absolutePeriod: this.absolutePeriod.endDate ? {
                startDate: this.type === SupplyStagePeriodType.ABSOLUTE ? (this.absolutePeriod.startDate?.toISOString() ?? null) : null,
                endDate: this.type === SupplyStagePeriodType.ABSOLUTE ? this.absolutePeriod.endDate!.toISOString() : null,
                fromContractConclusion: this.absolutePeriod.fromContractConclusion ?? false
            } : null,
            address: this.address?.toJson() ?? null
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoContractSpecificationSupplyStage): ContractSpecificationSupplyStage {
        return Object.assign(Object.create(ContractSpecificationSupplyStage.prototype), dto, {
            volume: new Decimal(dto.volume),
            address: dto.address ? Address.fromJson(dto.address) : null,
            relativePeriod: {
                ...dto.relativePeriod,
                startOffset: dto.type === SupplyStagePeriodType.RELATIVE ? dto.relativePeriod.startOffset : null,
                endOffset: dto.type === SupplyStagePeriodType.RELATIVE ? dto.relativePeriod.endOffset : null,
                finishYear: dto.type === SupplyStagePeriodType.RELATIVE ? dto.relativePeriod.finishYear : null
            },
            absolutePeriod: {
                startDate: dto.type === SupplyStagePeriodType.ABSOLUTE && dto.absolutePeriod?.startDate ? new Date(dto.absolutePeriod.startDate) : null,
                endDate: dto.type === SupplyStagePeriodType.ABSOLUTE && dto.absolutePeriod?.endDate ? new Date(dto.absolutePeriod.endDate!) : null,
                fromContractConclusion: dto.type === SupplyStagePeriodType.ABSOLUTE ? (dto.absolutePeriod?.fromContractConclusion ?? false) : false
            },
        });
    }
}
