// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/list_markers.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.stages[data-v-3fdb5c90] {\n    width: 100%;\n}\n.stage + .stage[data-v-3fdb5c90] {\n    margin-top: 15px;\n}\n.stage-header[data-v-3fdb5c90] {\n    display: flex;\n    flex-flow: row nowrap;\n\n    font-size: 13px;\n    cursor: pointer;\n}\n.stage-name[data-v-3fdb5c90] {\n    font-weight: 600;\n    margin-bottom: 3px;\n}\n.stage-actions[data-v-3fdb5c90] {\n    display: flex;\n    flex-flow: row nowrap;\n    flex-grow: 1;\n\n    align-items: stretch;\n    justify-content: flex-end;\n}\n.expander-toggle[data-v-3fdb5c90] {\n    margin-top: 2px;\n    margin-right: 10px;\n\n    cursor: pointer;\n    user-select: none;\n}\n.expander-icon[data-v-3fdb5c90] {\n    width: 12px;\n    height: 12px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    background-position-y: -12px;\n}\n.expander-icon.open[data-v-3fdb5c90] {\n    background-position-y: 0;\n}\n.stage-content[data-v-3fdb5c90] {\n    margin-top: 20px;\n}\n.stage-content > .stage + .stage[data-v-3fdb5c90] {\n    margin-top: 30px;\n}\n.execution-state[data-v-3fdb5c90] {\n    margin-bottom: 20px;\n}\n.execution-state[data-v-3fdb5c90] label {\n    font-size: 13px !important;\n    align-items: center;\n}\n.execution-state[data-v-3fdb5c90] .radio-component {\n    margin-right: 5px;\n}\n.execution-state > * + *[data-v-3fdb5c90] {\n    margin-top: 5px;\n}\n.datepicker[data-v-3fdb5c90] {\n  margin-left: 15px;\n}\n", ""]);
// Exports
module.exports = exports;
