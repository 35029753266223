/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from "react";
import styles from '../CreateSession.module.css';
import {FormControlEshopInput} from "@/components/eshop/FormControlEshopInput";
import {Checkbox, Pager, PrimaryButton} from "@/components/eshop";
import {Select} from "@/components/eshop/Select/Select";
import {DatePicker} from "@/components/primitive";
import {
    deleteAddedItems,
    onAddNewStage,
    onChangeAddress,
    onChangeDaytype,
    onChangePrice,
    onChangeShow,
    onChangeSupplyBegin,
    onChangeSupplyEnd,
    onChangeSupplyEndDate,
    onChangeSupplyStartDate,
    onChangeSupplyType,
    onChangeVolume,
    onDeleteAllStages,
    onDeleteStage,
    onSelectExistingItemService,
    resetExecutionStages,
    ServicesStore,
    SpecificationType,
    SubjectDeclarationForCreationProduct,
    SubjectDeclarationForCreationPurchase,
    SubjectDeclarationForCreationService
} from "../store";
import {Modal} from "@/components/eshop/Modal";
import {TooltipIcon} from "@/components/TooltipIcon";
import {firstMaybe} from "@/arrayUtils";
import {SubjectClassFilterStore} from "@/components/eshop/SubjectClassFilterStore";
import _ from "lodash";
import {HeaderWithTooltip, isSorted, SortFilter} from "../Session/CreateSession";
import {SortIcons} from "@/components/SortIcons";
import {ModalCharacteristics, ModalCharacteristicsForProducts} from "./ModalCharacteristics";
import {ModalSearch} from "./ModalSearch";
import {ModalSupply} from "./SupplyModal";
import {formatNumber} from "@/NumberFormatting";
import {NumberInput} from "@/components/eshop/NumberInput";
import {CancelButton} from "@/components/eshop/Buttons";
import {Section} from "@/components/eshop/Section/Section";
import {
    AddGraphButton,
    ChevronButton,
    CopyButton,
    DetailsButton,
    PlusButton,
    RefreshButton,
    RemoveButton,
    ResetFiltersButton,
    ShowFiltersButton
} from "@/components/eshop/IconButton/IconButtons";
import {RadioButton} from "@/components/eshop/RadioButton/RadioButton";
import {Card} from "@/components/eshop/Card/Card";
import {j} from "@/reactUtils";
import { Input } from "@/components/eshop/Input/Input";


interface SpecificationsCardProps {
    services: SubjectDeclarationForCreationService[]
    products: SubjectDeclarationForCreationProduct[]
    purchases: SubjectDeclarationForCreationPurchase[]
    type: SpecificationType
    store: ServicesStore
    subjectClassSelector: SubjectClassFilterStore
}

export const workDaysOptions = [{ value: 'NORMAL', name: 'Календарные' }, { value: 'WORKDAY', name: 'Рабочие' }];

export const HeaderInline = (props: { children: React.ReactNode; align?: "right" | "left" | "center" }) =>
    <th className={styles.cell} style={{textAlign: props.align}}>
        <div className={styles.header__sortButtons}>
            {props.children}
        </div>
    </th>;

export const SpecificationsCard = (props: SpecificationsCardProps) => {
    const store = props.store;

    const [supplyModalItem, setSupplyModalItem] = useState<{ id: string; copyMode: boolean } | null>(null);
    const [showSearch, setShowSearch] = useState(false);
    const [showCharacteristicsId, setShowCharacteristicsId] = useState<string | null>(null);
    const [deleteFunction, setDeleteFunction] = useState<null | (() => void)>(null);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const [sortSpec, setSortSpec] = useState<SortFilter>(null);
    const isSelected = (id: string) => store.selectedExistingIds.indexOf(id) !== -1;

    const dataType = props.type;
    const data = dataType === SpecificationType.Services ? props.services : dataType === SpecificationType.Products ? props.products : props.purchases;
    const { currentSession } = store;
    const status = currentSession?.status;
    const canChange = currentSession === null || status === 'DRAFT' || status === 'UPDATED';

    const [isFilterOpened, setFilterOpened] = useState(false);
    const [name, setName] = useState('');
    const [priceFrom, setPriceFrom] = useState<number | null>(null);
    const [priceTo, setPriceTo] = useState<number | null>(null);
    const [volumeFrom, setVolumeFrom] = useState<number | null>(null);
    const [volumeTo, setVolumeTo] = useState<number | null>(null);
    const [sumFrom, setSumFrom] = useState<number | null>(null);
    const [sumTo, setSumTo] = useState<number | null>(null);
    const [specPageSize, setSpecPageSize] = useState<number>(10);

    const clearFilters = () => {
        setName('');
        setPriceFrom(null);
        setPriceTo(null);
        setVolumeFrom(null);
        setVolumeTo(null);
        setSumFrom(null);
        setSumTo(null);
    };

    const filtered = data
        .filter(i => {
            const nameFromSpec = dataType === SpecificationType.Products
                ? (i as SubjectDeclarationForCreationProduct).product.name
                : dataType === SpecificationType.DirectPurchase
                    ? (i as SubjectDeclarationForCreationPurchase).purchase.name
                    : i.subjectDeclaration.subject;
            return nameFromSpec.toLowerCase().indexOf(name.toLowerCase()) !== -1;
        })
        .filter(i => {
            return (priceFrom ? i.price > priceFrom : true) && (priceTo ? priceTo > i.price : true);
        })
        .filter(i => {
            const volume = i.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0);
            return (volumeFrom ? volume > volumeFrom : true) && (volumeTo ? volumeTo > volume : true);
        })
        .filter(i => {
            const volume = i.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0);
            const sum = volume * i.price;
            return (sumFrom ? sum > sumFrom : true) && (sumTo ? sumTo > sum : true);
        });

    const specsSorted = () => sortSpec
        ? _.orderBy(filtered, a => _.get(a, sortSpec.field), [sortSpec.sort])
        : filtered;

    const modalDeleteStages = (confirmDelete: (() => void) | null) => {
        const onConfirm = () => {
            if (confirmDelete) {
                confirmDelete();
            }
            setDeleteFunction(null);
        };

        return confirmDelete
            ? <Modal medium controls={<>
                <CancelButton onClick={() => setDeleteFunction(null)}>Отмена</CancelButton>
                <PrimaryButton onClick={() => onConfirm()}>Удалить</PrimaryButton>
            </>}
                onClose={() => setDeleteFunction(null)} title="Удалить все графики">
                <div className={styles.modalControlsContainer}>
                    <div>
                        Вы действительно хотите удалить все графики для этой спецификации?
                    </div>
                </div>
            </Modal >
            : <div />;
    };

    const onSupplyModalOpen = (e: React.MouseEvent<unknown, MouseEvent>) => {
        e.stopPropagation();
        const firstSpecification = firstMaybe(data);
        if (firstSpecification) {
            setSupplyModalItem({ id: firstSpecification.idForKey, copyMode: false });
        }
    };

    const idForSort = dataType === SpecificationType.Products
        ? 'product.steId'
        : dataType === SpecificationType.DirectPurchase
            ? 'purchase.offerId'
            : 'subjectDeclaration.id';

    const nameForSort = dataType === SpecificationType.Products
        ? 'product.name'
        : dataType === SpecificationType.DirectPurchase
            ? 'purchase.name'
            : 'subjectDeclaration.subject';

    const setAllSelected = (data: SubjectDeclarationForCreationService[]) => {
        data.forEach(i => {
            onSelectExistingItemService(i.idForKey);
        });
        setIsAllSelected(data.every(d => {
            return isSelected(d.idForKey);
        }));
    };

    const handleSelectSet = (id: string) => {
        onSelectExistingItemService(id);
        setIsAllSelected(data.every(d => {
            return isSelected(d.idForKey);
        }));
    };

    return <>
        <ModalSupply data={data}
            supplyModalItem={supplyModalItem}
            setSupplyModalItem={setSupplyModalItem}
            dataType={dataType}
            setDeleteFunction={setDeleteFunction} />
        <ModalSearch show={showSearch}
            setShowSearch={setShowSearch}
            store={store}
            dataType={dataType}
            subjectClassSelector={props.subjectClassSelector} />
        {dataType === SpecificationType.Services
            ? <ModalCharacteristics serviceId={showCharacteristicsId}
                setShowCharacteristicsId={setShowCharacteristicsId}
                data={data}
                canChange={canChange} />
            : <ModalCharacteristicsForProducts id={showCharacteristicsId}
                setShowCharacteristicsId={setShowCharacteristicsId}
                data={data}
                canChange={canChange} />}
        {modalDeleteStages(deleteFunction)}
        <Section required
                 canBeHidden
                 title={<>Спецификации<TooltipIcon /></>}>
            <Card direction={"vertical"}>
                <div className={styles.controlsWrapper}>
                    <div className={styles.buttonsContainer}>
                        <PlusButton onClick={() => setShowSearch(true)} disabled={!canChange} />
                        <AddGraphButton onClick={e => e && onSupplyModalOpen(e)} disabled={!canChange} />
                        <ShowFiltersButton onClick={() => setFilterOpened(!isFilterOpened)} />
                        <ResetFiltersButton onClick={clearFilters} />
                        <RefreshButton />
                        <RemoveButton onClick={() => deleteAddedItems({ specType: dataType, ids: store.selectedExistingIds })} disabled={!store.selectedExistingIds.length || !canChange} />
                    </div>
                </div>
                <table className={styles.cardTable}>
                    <thead>
                    <tr className={styles.tableRow}>
                        <HeaderInline>
                            <Checkbox title={"Выделить все"} disabled={!canChange} value={isAllSelected} onChange={() => setAllSelected(data)} />
                        </HeaderInline>
                        <HeaderInline>
                            <SortIcons sorted={null} setSort={() => {}} />
                        </HeaderInline>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted(idForSort, sortSpec)} setSort={sort => setSortSpec({ field: 'subjectDeclaration.id', sort })} />
                            {dataType === SpecificationType.Services ? 'Номер СПГЗ' : dataType === SpecificationType.Products ? 'Номер СТЕ' : 'Номер оферты'}
                        </HeaderWithTooltip>
                        <HeaderInline >
                            <SortIcons sorted={isSorted(nameForSort, sortSpec)} setSort={sort => setSortSpec({ field: 'subjectDeclaration.subject', sort })} />
                            Наименование
                        </HeaderInline>
                        {dataType !== SpecificationType.DirectPurchase && <th className={styles.cell}>Характеристики</th>}
                        <HeaderInline >
                            <SortIcons sorted={isSorted('price', sortSpec)} setSort={sort => setSortSpec({ field: 'price', sort })} />
                            Цена
                        </HeaderInline>
                        <th className={styles.cell}>
                            Количество
                        </th>
                        <HeaderInline >
                            <SortIcons sorted={isSorted('subjectDeclaration.measurementUnits[0]?.name', sortSpec)} setSort={sort => setSortSpec({ field: 'subjectDeclaration.measurementUnits[0]?.name', sort })} />
                            Ед.измерения
                        </HeaderInline>
                        <HeaderInline >
                            <SortIcons sorted={isSorted('price', sortSpec)} setSort={sort => setSortSpec({ field: 'price', sort })} />
                            Сумма, руб
                        </HeaderInline>
                    </tr>
                    {isFilterOpened && <tr>
                        <th className={styles.cell}></th>
                        <th colSpan={2} className={styles.cell}><FormControlEshopInput small onChange={e => setName(e.target.value)} placeholder="Введите значение" /></th>
                        <th className={styles.cell}></th>
                        <th className={styles.cell}>
                            <NumberInput small value={priceFrom} onChange={e => setPriceFrom(e)} placeholder="от" />
                            <NumberInput small value={priceTo} onChange={e => setPriceTo(e)} placeholder="до" />
                        </th>
                        <th className={styles.cell}>
                            <NumberInput small value={volumeFrom} onChange={e => setVolumeFrom(e)} placeholder="от" />
                            <NumberInput small value={volumeTo} onChange={e => setVolumeTo(e)} placeholder="до" />
                        </th>
                        <th className={styles.cell}></th>
                        <th className={styles.cell}>
                            <NumberInput small value={sumFrom} onChange={e => setSumFrom(e)} placeholder="от" />
                            <NumberInput small value={sumTo} onChange={e => setSumTo(e)} placeholder="до" />
                        </th>
                        <th className={styles.cell}></th>
                    </tr>}
                    </thead>
                    <tbody>
                    {specsSorted().map(s => {
                        const volume = s.stages.reduce((a, b) => a + parseInt(b.volume || '0'), 0);
                        const currentMode = s.stageType;

                        const name = dataType === SpecificationType.Products
                            ? (s as SubjectDeclarationForCreationProduct).product.name
                            : dataType === SpecificationType.DirectPurchase
                                ? (s as SubjectDeclarationForCreationPurchase).purchase.name
                                : s.subjectDeclaration.subject;

                        const id = dataType === SpecificationType.Products
                            ? (s as SubjectDeclarationForCreationProduct).product.steId
                            : dataType === SpecificationType.DirectPurchase
                                ? (s as SubjectDeclarationForCreationPurchase).purchase.offerId
                                : s.subjectDeclaration.subject;

                        return <React.Fragment key={s.idForKey}>
                            <tr>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    <Checkbox value={isSelected(s.idForKey)} onChange={() => handleSelectSet(s.idForKey)} disabled={!canChange} />
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`} onClick={() => onChangeShow({ specType: dataType, id: s.idForKey })}>
                                    <div className={`${s.stages.length ? styles.showStages : styles.showStagesError}`}>
                                        <ChevronButton rotated={!s.stages.length} />
                                        <span className={`${styles.count} ${s.stages.length ? '' : styles.countError}`}>{s.stages.length}</span>
                                    </div>
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>{id}</td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>{name}</td>
                                {dataType !== SpecificationType.DirectPurchase && <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {s.characteristicDeclarations.length || dataType === SpecificationType.Products
                                        ? <DetailsButton onClick={() => setShowCharacteristicsId(s.idForKey)} />
                                        : 'Нет характеристик'}
                                </td>}
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {(dataType === SpecificationType.Services || dataType === SpecificationType.Products)
                                        ? <Input checkEmpty
                                                value={s.price}
                                                unlimitedFloat
                                                isMoney
                                                small
                                                validate={(v) => validatePrice(v, (s as any).product?.referentPrice)}
                                                disabled={!canChange}
                                                onChange={e => onChangePrice({ id: s.idForKey, price: onChangeNumberNew(e.target.value, true) })}
                                                placeholder="Введите значение" />
                                        : <span>{formatNumber(s.price)}</span>}
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`} style={{width: 80}}><FormControlEshopInput value={volume} disabled small onChange={() => { }} placeholder="Введите значение" /></td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>{firstMaybe(s.subjectDeclaration.measurementUnits)?.name || ''}</td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>{formatNumber(volume * s.price)} руб</td>
                            </tr>
                            {s.showSupply && <tr className={`${styles.tableRow} ${styles.tableRow_darker}`}>
                                <td colSpan={9} className={styles.deliveryContainer}>
                                    {canChange &&
                                        <div className={styles.planTabs} style={{justifyContent: "space-between"}}>
                                            <div style={{display: "flex"}}>
                                                <RadioButton id={"radio-relative"} checked={currentMode === "RELATIVE"} onChange={() => onChangeSupplyType({ specType: dataType, type: 'RELATIVE', id: s.idForKey })} label={"Сроки поставки"} />
                                                <RadioButton id={"radio-absolute"} checked={currentMode === "ABSOLUTE"} onChange={() => onChangeSupplyType({ specType: dataType, type: 'ABSOLUTE', id: s.idForKey })} label={"Даты поставки"} />
                                            </div>

                                            <div style={{display: "flex", gap: 5}}>
                                                <PlusButton onClick={() => onAddNewStage({specType: dataType, id: s.idForKey})} title={"Добавить график"} />
                                                <CopyButton onClick={() => setSupplyModalItem({ id: s.idForKey, copyMode: true })} title={"Копировать график"} disabled={!s.stages.length && data.length > 0} />
                                                <RemoveButton onClick={() => setDeleteFunction(() => () => onDeleteAllStages({ specType: dataType, id: s.idForKey }))} title={"Удалить все графики"} disabled={!s.stages.length} />
                                            </div>
                                        </div>
                                    }
                                    {currentMode === "RELATIVE" && <table className={styles.cardTable}>
                                        <thead>
                                        <tr className={styles.tableRow}>
                                            <th className={styles.cell}>Количество</th>
                                            <th className={styles.cell}>Тип дней</th>
                                            <th className={styles.cell}>Начало поставки</th>
                                            <th className={styles.cell}>Конец поставки</th>
                                            <th className={`${styles.cell} ${!canChange ? styles.supplyAddress : ''}`}>Адрес поставки</th>
                                            {canChange && <th className={styles.cell}></th>}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!s.stages.length && noStagesWarning()}
                                        {s.stages.map((stage, i) => <tr key={i}>
                                            <td className={j(styles.cell, styles.cell_padded)}>
                                                <FormControlEshopInput clearable
                                                                       checkEmpty
                                                                       small
                                                                       disabled={!canChange}
                                                                       value={showValueEmpty(stage.volume)}
                                                                       onChange={e => onChangeVolume({ specType: dataType, volume: onChangeNumber(e.target.value), id: s.idForKey, stageNumber: i })}
                                                                       placeholder="Введите значение" />
                                            </td>
                                            <td className={j(styles.cell, styles.cell_padded)}>
                                                <Select
                                                    fullWidth
                                                    small
                                                    disabled={!canChange}
                                                    value={stage.relativePeriod ? stage.relativePeriod.startOffsetType : ''}
                                                    onSelect={option => option && onChangeDaytype({ specType: dataType, type: option, id: s.idForKey, stageNumber: i })}
                                                    options={workDaysOptions} />
                                            </td>
                                            <td className={j(styles.cell, styles.cell_padded)}>
                                                <NumberInput clearable
                                                             checkEmpty
                                                             value={stage.relativePeriod?.startOffset}
                                                             disabled={!canChange}
                                                             small
                                                             disableFixed
                                                             disableMask
                                                             maxValue={1096}
                                                             validate={(v) => {
                                                                 if (!stage.relativePeriod?.startOffset) return {text: "Обязательно для заполнения"};
                                                                 if (stage.relativePeriod.endOffset && stage.relativePeriod.endOffset < +v) return {text: "Поле 'Начало поставки' не должно быть больше поля 'Окончание поставки'"};
                                                                 return {text: ""};
                                                             }}
                                                             onChange={e => onChangeSupplyBegin({ specType: dataType, day: e, id: s.idForKey, stageNumber: i })}
                                                             placeholder={"Введите значение"} />
                                            </td>
                                            <td className={j(styles.cell, styles.cell_padded)}>
                                                <NumberInput clearable
                                                             checkEmpty
                                                             value={stage.relativePeriod?.endOffset}
                                                             disabled={!canChange}
                                                             small
                                                             disableFixed
                                                             disableMask
                                                             maxValue={1096}
                                                             validate={(v) => {
                                                                 if (!stage.relativePeriod?.endOffset) return {text: "Обязательно для заполнения"};
                                                                 return {text: ""};
                                                             }}
                                                             onChange={e => onChangeSupplyEnd({ specType: dataType, day: e, id: s.idForKey, stageNumber: i })}
                                                             placeholder={"Введите значение"} />
                                            </td>
                                            <td className={j(styles.cell, styles.cell_padded)}>
                                                <FormControlEshopInput clearable
                                                                       checkEmpty
                                                                       value={stage.address}
                                                                       disabled={!canChange}
                                                                       small
                                                                       onChange={e => onChangeAddress({ specType: dataType, address: e.target.value, id: s.idForKey, stageNumber: i })}
                                                                       placeholder="Введите значение" />
                                            </td>
                                            {canChange && <td className={j(styles.cell, styles.cell_padded)}><RemoveButton onClick={() => {
                                                resetExecutionStages();
                                                onDeleteStage({specType: dataType, id: s.idForKey, stageNumber: i});
                                            }} /></td>}
                                        </tr>)}
                                        </tbody>
                                    </table>}
                                    {currentMode === "ABSOLUTE" && <table className={styles.cardTable}>
                                        <thead>
                                        <tr className={styles.tableRow}>
                                            <th className={styles.cell}>Количество</th>
                                            <th className={styles.cell}>Начало поставки</th>
                                            <th className={styles.cell}>Конец поставки</th>
                                            <th className={`${styles.cell} ${!canChange ? styles.supplyAddress : ''}`}>Адрес поставки</th>
                                            {canChange && <th className={styles.cell}></th>}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!s.stages.length && noStagesWarning()}
                                        {s.stages.map((stage, i) => <tr key={i}>
                                            <td className={styles.cell}>
                                                <FormControlEshopInput clearable
                                                                       checkEmpty
                                                                       small
                                                                       disabled={!canChange}
                                                                       value={showValueEmpty(stage.volume)}
                                                                       onChange={e => onChangeVolume({ specType: dataType, volume: onChangeNumber(e.target.value), id: s.idForKey, stageNumber: i })}
                                                                       placeholder="Введите значение" />
                                            </td>
                                            <td className={styles.cell}>
                                                <DatePicker value={stage.absolutePeriod?.startDate ?? undefined}
                                                            disabled={!canChange}
                                                            mode="days"
                                                            maxWidth={"300px"}
                                                            appearance="eshop"
                                                            small
                                                            onChange={day => onChangeSupplyStartDate({ specType: dataType, day, id: s.idForKey, stageNumber: i })} />
                                            </td>
                                            <td className={styles.cell}>
                                                <DatePicker value={stage.absolutePeriod?.endDate ?? undefined}
                                                            disabled={!canChange}
                                                            mode="days"
                                                            maxWidth={"300px"}
                                                            appearance="eshop"
                                                            small
                                                            onChange={day => onChangeSupplyEndDate({ specType: dataType, day, id: s.idForKey, stageNumber: i })} />
                                            </td>
                                            <td className={styles.cell}>
                                                <FormControlEshopInput clearable
                                                                       checkEmpty
                                                                       value={stage.address}
                                                                       small
                                                                       onChange={e => onChangeAddress({ specType: dataType, address: e.target.value, id: s.idForKey, stageNumber: i })}
                                                                       placeholder="Введите значение"
                                                                       disabled={!canChange} />
                                            </td>
                                            {canChange && <td className={styles.cell}><div className={`${styles.iconButton} ${styles.buttonDelete}`} /></td>}
                                        </tr>)}
                                        </tbody>
                                    </table>}
                                </td>
                            </tr>}
                        </React.Fragment>;
                    })}
                    </tbody>
                </table>
                <div className={styles.specificationsCardPager}>
                    <Pager page={{from: 0, to: 1, totalCount: specsSorted().length, items: specsSorted()}} goTo={() => {}} pageSize={specPageSize} changePageSize={v => setSpecPageSize(v)} />
                    <div>Всего записей {specsSorted().length}</div>
                </div>
            </Card>
        </Section>
    </>;
};

export const noStagesWarning = () => <tr>
    <td className={styles.noStages} colSpan={6}>
        Необходимо добавить график поставки
    </td>
</tr>;

export const showValueEmpty = (value: string | number | undefined | null) => (value === null || value === undefined)
    ? ''
    : value;

export const onChangeNumber = (value: string) => {
    const number = parseFloat(value.replace(',', '.').replace(' ', ''));
    return isNaN(number) ? null : number;
};

export const onChangeNumberNew = (value: string, infiniteFloat?: boolean) => {
    if(value === "") return 0;
    const trimmedStr = value.trim();
    if(!trimmedStr.length) return null;

    const cleanedStr = value.replaceAll(/\s/g, "");
    const regExp = infiniteFloat ? /^\d+[,.]?(\d+)?$/ :  /^\d+[,.]?(\d{1,2})?$/;
    if(regExp.test(cleanedStr)) {
        if(Array.from(cleanedStr.matchAll(/[,.]/g)).length > 1) return null;
        const price = parseFloat(cleanedStr.replace(",", "."));
        if(!isNaN(price)) {
            return price;
        }
    }
    return null;
};

export const validatePrice = (value: string, refPrice?: number) => {
    const number = onChangeNumber(value);
    if(number === null) return {text: ""};
    if(number <= 0) return {text: "Цена должна быть больше нуля"};
    if(refPrice !== undefined  && refPrice !== null && number > refPrice ) {
        return {text: "Указанная цена не должна превышать референтную цен"};
    }
    return {text: ""};
};
