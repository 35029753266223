import {DtoLotPlannedPayment, LotPlannedPayment} from "@/models/ComposedLots/LotPlannedPayment";
import {AutoDto, AutoModel, MDMap} from "@/models/parsing";
import Decimal from "decimal.js";

interface LotFinancialSourceUsageMD {
    sourceId: string
    paymentId: string
    paymentAmount?: Decimal
    advanceAmount?: Decimal
}

export type DtoLotFinancialSourceUsage = AutoDto<LotFinancialSourceUsageMD>;
export type LotFinancialSourceUsage = AutoModel<LotFinancialSourceUsageMD>;

export const LotFinancialSourceUsage = {
    toDto(info: LotFinancialSourceUsage): DtoLotFinancialSourceUsage {
        return {
            sourceId: info.sourceId,
            paymentId: info.paymentId,
            paymentAmount: info.paymentAmount ? info.paymentAmount.toString() : new Decimal(0).toString(),
            advanceAmount: info.advanceAmount ? info.advanceAmount.toString() : new Decimal(0).toString()
        };
    },
    fromDto(info: DtoLotFinancialSourceUsage): LotFinancialSourceUsage {
        return {
            sourceId: info.sourceId,
            paymentId: info.paymentId,
            paymentAmount: info.paymentAmount ? new Decimal(info.paymentAmount) : new Decimal(0),
            advanceAmount: info.advanceAmount ? new Decimal(info.advanceAmount) : new Decimal(0),
        };
    }
};


