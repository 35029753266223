import React, {CSSProperties, useState} from "react";
import styles from "./Input.module.css";
import {TooltipIcon} from "@/components/TooltipIcon";
import {j} from "@/reactUtils";
import { errorIcon } from "@/components/eshop/NumberInput";

interface InputProps {
    // отображает блок сверху инпута на границе инпута
    label?: string
    value: string | number | readonly string[]
    onChange: (e: { target: { value: string } }) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    /* Фунция для валидации значения и отображения ошибок: красный бордер, красные цифры в инпуте,
        текст в хинте ошибки под полем, который возвращается из функции (ничего если пустое значение "")
    */
    validate?: (v: string, refValue?: number) => {text: string}
    /* Нужен для работы со строками, которые являются числами формата xxx,xx.
       Так же влияет на отображение значения в поле (отображается отформатированная строка вместо value, не то что в сторе),
       например заменяем "." на "," или для того, чтобы показать в поле "123," и т.п.
    */
    isMoney?: boolean
    disabled?: boolean
    clearable?: boolean
    checkEmpty?: boolean
    // Визуальные css изменения, чтобы инпут красиво смотрелся в коротком виде как например на странице создания КС в поле "Цена" спецификации
    small?: boolean
    /* Изначально планировалась работа с 2 цифрами после запятой, но в спецификациях может приходить цена товара
       с 3 и более цифрами после запятой, проп позволяет вносить более 2 знаков после запятой для корректности расчетов
    */
    unlimitedFloat?: boolean
    // Если передан вместе с label, то отображает тултип с вопросиком сверху поля на границе инпута
    withTooltip?: boolean
    // Если передан вместе с label, то отображает символ обязательного поля "*"
    required?: boolean
    // Счетчик оставшихся символов
    withHint?: boolean
    readonly?: boolean
    fullWidth?: boolean
    background?: string
    placeholder?: string
}

export const Input = (props: InputProps) => {
    const [displayValue, setDisplayValue] = useState(typeof props.value === "number" || typeof props.value === "string" 
        ? props.value.toString().replace(".", ",") 
        : props.value.toString());

    const changeDisplayName = (v: string) => {
        if(v === "") setDisplayValue("0");
        const trimmedStr = v.trim();
        if(!trimmedStr.length) return;

        const cleanedStr = v.replaceAll(/\s/g, "");
        const regExp = props.unlimitedFloat ? /^\d+[,.]?(\d+)?$/ :  /^\d+[,.]?(\d{1,2})?$/;
        if(regExp.test(cleanedStr)) {
            if(Array.from(cleanedStr.matchAll(/[,.]/g)).length > 1) return;
            setDisplayValue(cleanedStr.replace(".", ","));
        }

    };

    let errorText = "";
    if(props.validate) {
        errorText = props.validate(props.value.toString()).text;
    }

    return (
        <div className={j(styles.input__wrapper, props.fullWidth && styles.input__wrapper_fullWidth)}>
            <fieldset style={{background: props.background, padding: props.background ? 0 : props.small ? "2px 10px 3px 10px" : void 0}}
                      className={j(styles.input__fieldset, props.disabled && styles.input__fieldset_disabled, props.fullWidth && styles.input__fieldset_fullWidth, errorText ? styles.errorBorder : undefined)}>
                {props.label &&
                    <legend className={j(styles.input__label, props.disabled && styles.input__label_disabled)}>
                        {props.label}
                        {props.required ? <span className={styles.required}>*</span> : ''}
                        {props.withTooltip && <TooltipIcon />}
                    </legend>
                }
                <input value={props.isMoney ? displayValue : props.value}
                       onChange={v => {
                        if(props.isMoney)
                            changeDisplayName(v.target.value);
                        return props.onChange(v);
                        }}
                       onKeyDown={props.onKeyDown}
                       className={j(styles.input__field, props.small && styles.inputSmall, errorText ? styles.error : undefined)}
                       maxLength={100}
                       style={{marginLeft: props.small ? 0 : 10}}
                       disabled={props.disabled}
                       placeholder={props.placeholder} />
                {(props.clearable && !props.disabled) && <div onClick={() => props.onChange && props.onChange({ target: { value: '' } })} className={styles.closeButton} />}
            </fieldset>
            {errorText && <div className={`${styles.errorHint}`}>
                                    <div style={{width: 16, height: 16}}>
                                        {errorIcon}
                                </div>
                                    {errorText}
                                </div>}
            {props.withHint && props.value && <div className={styles.input__hint}>Осталось {100 - props.value?.toString().length} символов из 100 </div>}
        </div>
    );
};
