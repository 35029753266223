import React from "react";
import styles from '../CreateSession.module.css';
import {
    changeDuration,
    changeEmail,
    changeFio,
    changeLaw, changeMaxPrice,
    changePhone, changePurchasePerUnit,
    getWinningSupplier,
    ServicesStore, SubjectDeclarationForCreationService
} from "../store";
import moment from "moment";
import { Checkbox } from "@/components/eshop";
import { TooltipIcon } from "@/components/TooltipIcon";
import { firstMaybe } from "@/arrayUtils";
import { shortRegNumber } from "@/api/Sessions";
import router from "@/router";
import { formatNumber } from "@/NumberFormatting";
import {Section} from "@/components/eshop/Section/Section";
import {Card} from "@/components/eshop/Card/Card";
import {Input} from "@/components/eshop/Input/Input";
import {Select} from "@/components/eshop/Select/Select";
import {CardInnerWrapper} from "@/components/eshop/CardInnerWrapper/CardInnerWrapper";
import {FieldsetText} from "@/components/eshop/FieldsetText/FieldsetText";
import { showModal } from "@/EventBus";
import { PickContactModal } from "@/modal-views/PickContactModal";
import { Person } from "@/views/Procedures2020/view/sections/infoSection/SelectContactPersonModal";

interface GeneralInfoCardProps {
    store: ServicesStore
    canChange: boolean
    data: SubjectDeclarationForCreationService[]
    initialAmount: number
}

type EditIconProps = {
    isDisabled: boolean
    saveFio: (payload: string) => string
    savePhone: (payload: string) => string
    saveEmail: (payload: string) => string
    existingContact?: Person
};

const getExistingContact = (store: ServicesStore): Person | undefined => {
    if(store.fio && store.phone && store.email) {
        const splittedName = store.fio.split(" ");
        return {
            primary: true,
            lastName: splittedName[0] ?? "",
            firstName: splittedName[1] ?? "",
            middleName: splittedName[2] ?? "",
            phone: store.phone,
            mail: store.email
        };
    }
};

const mockPersons = [
    {
        primary: true,
        firstName: "Максим",
        lastName: "Волков",
        middleName: "Максимович",
        mail: "business@eaist.emu",
        phone: "+7 (495) 727-28-37",
    },
    {
        primary: false,
        firstName: "Александр",
        lastName: "Дикарев",
        middleName: "Владимирович",
        mail: "contact@eaist.emu",
        phone: "+7 (495) 000-72-27",
    },
    {
        primary: false,
        firstName: "Александра",
        lastName: "Петухова",
        middleName: "Артёмовна",
        mail: "sales@eaist.emu",
        phone: "+7 (495) 495-49-94",
    },
    {
        primary: true,
        firstName: "Иван",
        lastName: "Иванов",
        middleName: "Иванович",
        phone: "+7(495)123-45-67 (1)",
        mail: "no-reply@mos.ru",
    }
];

const EditIcon: React.FC<EditIconProps> = x => {

    return  <svg width="16" height="16" viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg" 
                onClick={async () => {
                    if(x.isDisabled) return;
                    const res = await showModal(PickContactModal, {persons: mockPersons, existing: x.existingContact});
                    if(res) {
                        x.saveFio(res.name);
                        x.savePhone(res.phoneNumber);
                        x.saveEmail(res.email);
                    }
                }}
                style={x.isDisabled ? {fill: "#999"} : {fill: "#53b2f8", cursor: "pointer"} }>
                <path d="M15.924 4.22502V4.35502C15.904 4.40502 15.884 4.46502 15.864 4.51502C15.83 4.58428 15.7862 4.64828 15.734 4.70502V4.79502L5.73398 14.795H5.68398C5.63374 14.8408 5.5763 14.8779 5.51398 14.905C5.45825 14.9429 5.39772 14.9732 5.33398 14.995H5.27398L1.27398 15.995C1.19429 16.005 1.11367 16.005 1.03398 15.995C0.902296 15.9963 0.771672 15.9714 0.649758 15.9216C0.527844 15.8718 0.41709 15.7981 0.323981 15.705C0.201816 15.5828 0.112894 15.4313 0.0656439 15.2651C0.0183933 15.0988 0.014379 14.9233 0.0539814 14.755L1.05398 10.755V10.685C1.07644 10.6232 1.10317 10.5631 1.13398 10.505L1.24398 10.345V10.285L11.244 0.285015L11.344 0.215015L11.514 0.105015L11.704 0.0350151H11.814C12.3975 -0.0482568 12.9921 -0.00181375 13.5556 0.171035C14.1191 0.343884 14.6375 0.638883 15.074 1.03501C15.4304 1.47802 15.6901 1.99069 15.8365 2.54008C15.9829 3.08947 16.0127 3.66342 15.924 4.22502ZM13.544 2.43502C13.1955 2.16893 12.7611 2.04075 12.324 2.07502L2.75398 11.595L2.22398 13.705L4.34398 13.185L13.924 3.64502C13.9351 3.21068 13.8015 2.78498 13.544 2.43502Z" fill="inherit"/>
            </svg>;
};

export const GeneralInfoCard = (props: GeneralInfoCardProps) => {
    const { store, canChange, data, initialAmount } = props;
    const firstData = firstMaybe(data);

    const subjectQuotationSession = data.length > 0
        ? data.length > 1
            ? store.sessionSubjectName
            : firstData?.subjectDeclaration.subject
        : '';
    const lawOptions = [{ name: "44-ФЗ", value: "F44" }, { name: "223-ФЗ", value: "F223" }];
    const hoursOptions = [{ name: "3", value: "3" }, { name: "6", value: "6" }, { name: "24", value: "24" }];


    const supplier = store.currentSession && getWinningSupplier(store.currentSession);

    return(
        <Section required title={<>Общие сведения<TooltipIcon /></>} canBeHidden>
            <Card direction={"vertical"}>
                <Card direction={"horizontal"} border={"thin"} padded>
                    <FieldsetText label={"Предмет КС"} value={subjectQuotationSession} withTooltip />
                </Card>
                <div className={styles.innerCardsWrapper}>
                    <Card direction={"vertical"} border={"thin"} padded>
                        <Select label={"Закон-основание"}
                                 value={store.law}
                                 options={lawOptions}
                                 onSelect={v => v && changeLaw(v)}
                                 required
                                 withTooltip
                                 disabled={!canChange}
                        />
                        <CardInnerWrapper>
                            <Checkbox disabled={!canChange} bold value={store.purchasePerUnit} onChange={() => changePurchasePerUnit(!store.purchasePerUnit)} caption="Закупка на единицу продукции" />
                            <TooltipIcon />
                        </CardInnerWrapper>
                        {store.purchasePerUnit && <Input value={store.maxPrice} onChange={v => v && changeMaxPrice(v.target.value)}
                                                          label={"Максимальная цена контракта, руб."}
                                                          placeholder={"Введите цену"}
                                                          isMoney
                                                          fullWidth
                                                          required />}
                        <FieldsetText label={"Начальная цена"} withTooltip value={`${formatNumber(initialAmount)} руб`} />
                        <Select label={"Продолжительность КС"}
                                value={store.duration || ""}
                                options={hoursOptions}
                                onSelect={v => v && changeDuration(v)}
                                withTooltip
                                unit="часа"
                                disabled={!canChange} />
                        <CardInnerWrapper className={styles.durationTooltip}>
                            {store.duration === '24'
                                ? 'Отмена недоступна для аукциона с длительностью в 24 часа по прошествии 2 часов'
                                : store.duration === '6'
                                    ? 'Отмена недоступна для аукциона с длительностью в 6 часов по прошествии 1 часа'
                                    : 'Аукцион длительностью 3 часа отменить нельзя. Максимальная общая цена не должна превышать 100 тыс. руб.'}
                        </CardInnerWrapper>
                    </Card>
                    <Card title={"Контактное лицо"} direction={"vertical"} border={"thin"} padded 
                        endAdornment={<EditIcon isDisabled={!canChange} saveFio={changeFio} savePhone={changePhone} saveEmail={changeEmail}
                            existingContact={getExistingContact(store)}/>}>
                        <Input withTooltip
                                label={"ФИО"}
                                value={store.fio}
                                disabled={!canChange}
                                onChange={e => e && changeFio(e.target.value)}
                                required
                                clearable
                                withHint />
                        <Input withTooltip
                                label={"Телефон"}
                                value={store.phone}
                                disabled={!canChange}
                                onChange={e => e && changePhone(e.target.value)}
                                required
                                clearable
                                withHint />
                        <Input withTooltip
                                label={"Электронная почта"}
                                value={store.email}
                                disabled={!canChange}
                                onChange={e => e && changeEmail(e.target.value)}
                                required
                                clearable
                                withHint />
                    </Card>
                    <Card title={"Результаты КС"} direction={"vertical"} border={"thin"} padded>
                        <FieldsetText label={store.law === "F44" ? 'Контракт' : 'Договор'}
                                      value={store.currentSession && store.currentSession.contract
                                          ? <a className={styles.linkButton}
                                               rel="noreferrer"
                                               target="_blank"
                                               onClick={() => router.push(`/contracts/${store.currentSession?.contract?.id}`)}>
                                              {shortRegNumber(store.currentSession.contract.regNumber)}
                                          </a>
                                          : 'Не сформирован'} />
                        <FieldsetText label={'Поставщик'}
                                      value={supplier
                                          ? <a className={styles.linkButton}
                                               rel="noreferrer"
                                               target="_blank">
                                              {supplier}
                                          </a>
                                          : 'Не сформирован'} />
                        <FieldsetText label={'Реестровый номер КС на ПП'}
                                      value={store.currentSession && store.currentSession.regNumber
                                          ? store.currentSession.regNumber
                                          : 'Не сформирован'} />
                        <FieldsetText label={'Дата и время окончания КС'}
                                      value={store.currentSession && store.currentSession.finished
                                          ? moment(store.currentSession.finished).format("DD.MM.YYYY HH:mm")
                                          : 'Не сформирован'} />
                    </Card>
                </div>
            </Card>
        </Section>
    );
};
