





































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { IFieldProps } from '@/components/vue/form-table/FieldBase';
import { SimpleRow } from '@/components/vue/form-table/FormFields';
import { ContractScope, ContractStageScope } from '../ContractScope';
import { ContractStage, ContractFinancialSourceUsage, ContractStagePaymentInfo, ContractStageExecutionInfo, ContractStageAcceptanceInfo, ContractStageDissolutionInfo } from '@/models/Contracts';
import StageTable from './StageTable.vue';
import { hs } from '@/components/TableHelpers';
import {
    FinancialSourceTypeStrings,
    ContractStageStatusStrings,
    DissolutionStatusStrings,
    LawType, ObjectStatus
} from '@/models/enums';
import Decimal from 'decimal.js';
import AddPayment from './AddPayment.vue';
import AddExecution from './AddExecution.vue';
import EventBus, { showModal } from '@/EventBus';
import { VueConstructor } from 'vue';
import {PaymentDocumentType, PaymentDocumentTypeStrings} from "@/models/enums/Contracts/PaymentDocumentType";
import { StagePayment, StageExecution, StageAcceptance, Stages, StageDissolution } from '@/api/Stages';
import AddAcceptance from './AddAcceptance.vue';
import AddDissolution from './AddDissolution.vue';
import { DissolutionTypeStrings, DissolutionReasonStrings } from '@/models/enums/Contracts/Dissolution';
import HintModal from '../modals/HintModal.vue';
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";
import {PickContractObligation, PickContractObligationModalReturn} from "@/modal-views/PickContractObligation";
import {
    AddObligationPaymentModal,
    AddObligationPaymentModalReturn
} from "@/views/CreateContract/execution/AddObligationPaymentModal";
import {File} from "@/models/Documents";

@Component({ components: { StageTable } })
export default class ContractStages extends Vue {
    @Prop() private props!: IFieldProps<SimpleRow<ContractScope>>;
    public factDate: Date | null = null;

    public get stages() {
        return this.props.source.stageScopes;
    }

    public get isGloballyForbidden() {
        return this.props.source.loading || this.props.source.contract.waitingForStageRegistration ||
            this.props.source.contract.status !== 'EXECUTING';
    }

    public isStageEditing(scope: ContractStageScope) {
        return scope.stage.status === 'CREATING' || scope.stage.status === 'CREATING_FIRST';
    }

    public expandedStage: ContractStage | null = null;

    public financesHeaders = hs<{ paymentInfo: ContractStagePaymentInfo[]; usage: ContractFinancialSourceUsage; usages: ContractFinancialSourceUsage[]}>(
        hs.any("№", (x, i) => (i + 1).toString(), "auto"),
        hs.any("Источник финансирования", x => [
            x.usage.source.source.year,
            FinancialSourceTypeStrings[x.usage.source.source.type],
            x.usage.source.source.code()
        ].join("/")),
        hs.decimal("% от суммы контракта по этапу", x => {
            const totalAmount = x.usages.reduce((sum, usg) => sum.add(usg.stageAmount), new Decimal(0));
            return x.usage.source.amount.div(totalAmount).mul(100).toNearest(0.01, Decimal.ROUND_UP);
        }),
        hs.decimal("Сумма этапа, руб.", x => x.usage.stageAmount), // TODO
        hs.decimal("В том числе авансовый платеж, руб.", x => x.usage.advanceAmount),
        hs.decimal("Из них оплачено, руб.",
            x => this.sum(
                x.paymentInfo
                    .filter(p => p.financialSource.id === x.usage.source.id)
                    .map(p => p.amount))),
    );

    public executionHeaders = hs<ContractStageExecutionInfo>(
        hs.any("№", (x, i) => (i + 1).toString(), "auto"),
        hs.date("Дата документа", x => {
            const doc = x.documents.find(d => d.id === x.primaryDocumentId);
            return doc && doc.date || null;
        }),
        hs.decimal("Сумма, руб.", x => this.sum(x.specifications.map(s => s.cost))),
        hs.any("Статус", x => ContractStageStatusStrings[x.status]),
        hs.any("Идентификатор ЕИС", x => x.specifications[0].eisNumber)
    );

    public acceptanceHeaders = hs<ContractStageAcceptanceInfo>(
        hs.any("№", (x, i) => (i + 1).toString(), "auto"),
        hs.any("Номер документа", x => x.documents[0] && x.documents[0].documentNumber || null),
        hs.date("Дата документа", x => x.documents[0] && x.documents[0].date || null),
        hs.any("Выявлены нарушения, препятствующие приемке", x => x.violationsDetected ? "Да" : "Нет"),
        hs.any("Тип экспертизы", x => x.isExternal ? "Внешняя" : "Внутренняя")
    );

    public paymentHeaders = hs<ContractStagePaymentInfo>(
        hs.any("№", (x, i) => (i + 1).toString(), "auto"),
        hs.any("Источник финансирования", x => [
            x.financialSource.source.year,
            FinancialSourceTypeStrings[x.financialSource.source.type],
            x.financialSource.source.code()
        ].join("/")),
        hs.any("Номер документа", x => x.documentNumber),
        hs.date("Дата документа", x => x.documentDate),
        hs.any("Тип документа", x => PaymentDocumentTypeStrings[x.documentType]),
        hs.decimal("Сумма, руб.", x => x.amount),
        hs.any("Статус", x => ContractStageStatusStrings[x.status]),
        hs.any("Регистрационный номер", x => ""),
    );

    public dissolutionHeaders = hs<ContractStageDissolutionInfo>(
        hs.any("№", (x, i) => (i + 1).toString(), "auto"),
        hs.any("Тип расторжения", x => DissolutionTypeStrings[x.type]),
        hs.any("Основание расторжения", x => DissolutionReasonStrings[x.reason]),
        hs.any("Номер документа", x => x.decisionNumber),
        hs.date("Дата документа", x => x.decisionDate),
        hs.any("Статус", x => this.props.source.contract.law === LawType.F44 ? DissolutionStatusStrings[x.status] : "Отправлено уведомление")
    );

    public async addEditAny<T extends {id: string}>(stage: ContractStage, src: T | null, modal: VueConstructor<Vue>, dataset: T[], cb?: (src: T | null) => void) {
        const props = { scope: this.props.source, stage, source: src };
        const result = await EventBus.callModal<typeof props, T>(modal, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                cb?.(src);
                dataset.push(r);
            } else {
                const ix = dataset.findIndex(x => x.id === r.id);
                dataset.splice(ix, 1, r);
            }
        }
    }

    private async addPaymentFromObligation(scope: ContractStageScope) {
        const contractNumber = this.props.source.contract.regNumber;
        const { payment } =  await showModal(AddObligationPaymentModal, {contractNumber}) as AddObligationPaymentModalReturn;

        if (payment) {
            const source: ContractStagePaymentInfo = new ContractStagePaymentInfo(
                null!,
                null!,
                this.props.source ? this.props.source.financialSources[0] : null!,
                PaymentDocumentType.CASH_PAYMENT_VOUCHER,
                payment.obligation?.typeNum ? String(payment.obligation.typeNum) : "1",
                new Date(),
                new Date(),
                new File(null!, 1, "report.pdf", "report.pdf"),
                payment.totalPrice,
                null!,
                ObjectStatus.CREATING_FIRST,
                null!
            );

            const response = await StagePayment.create(scope.stage.id, source);

            scope.paymentInfo.push(response);
        }
    }

    public async deleteAny<T>(src: T, api: {delete(_: T): Promise<void>}, dataset: T[]) {
        await api.delete(src);

        const ix = dataset.indexOf(src);
        if (ix !== -1) dataset.splice(ix, 1);
    }

    public addEditPaymentInfo(scope: ContractStageScope, src: ContractStagePaymentInfo | null) {
        this.addEditAny(scope.stage, src, AddPayment, scope.paymentInfo);
    }
    public async deletePaymentInfo(scope: ContractStageScope, src: ContractStagePaymentInfo | null) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить информацию о платеже?"});
        if (!confirmation)
            return;

        this.deleteAny(src, StagePayment, scope.paymentInfo);
    }

    public addEditExecutionInfo(scope: ContractStageScope, src: ContractStageExecutionInfo | null) {
        this.addEditAny(scope.stage, src, AddExecution, scope.executionInfo);
    }
    public async deleteExecutionInfo(scope: ContractStageScope, src: ContractStageExecutionInfo | null) {
        const r = await EventBus.callModal(HintModal, {
            header: "Удаление",
            text: "Вы уверены, что хотите удалить данное исполнение поставщиком?"
        });

        if (r.isOk) {
            this.deleteAny(src, StageExecution, scope.executionInfo);
        }
    }

    public async executionEisUpload(scope: ContractStageScope) {
        const response = await StageExecution.generateFromEis(scope.stage.id);

        if(response)
            scope.executionInfo.push(response);
    }

    public async paymentEisUpload(scope: ContractStageScope) {
        const response = await StagePayment.generateFromEis(scope.stage.id);

        if(response)
            scope.paymentInfo.push(response);
    }

    public addEditAcceptanceInfo(scope: ContractStageScope, src: ContractStageAcceptanceInfo | null) {
        this.addEditAny(scope.stage, src, AddAcceptance, scope.acceptanceInfo);
    }
    public deleteAcceptanceInfo(scope: ContractStageScope, src: ContractStageAcceptanceInfo | null) {
        this.deleteAny(src, StageAcceptance, scope.acceptanceInfo);
    }

    public addEditDissolutionInfo(scope: ContractStageScope, src: ContractStageDissolutionInfo | null) {
        const completeStageOnAdd = (src: ContractStageDissolutionInfo | null) => {
            if (!scope.dissolutionInfo.find(d => d.id === src?.id)) {
                scope.stage.completed = true;
            }
        };

        this.addEditAny(scope.stage, src, AddDissolution, scope.dissolutionInfo, completeStageOnAdd);
    }

    public async deleteDissolutionInfo(scope: ContractStageScope, src: ContractStageDissolutionInfo | null) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить информацию о расторжении?"});
        if (!confirmation)
            return;

        this.deleteAny(src, StageDissolution, scope.dissolutionInfo);
    }

    public sum(ds: Decimal[]) { return ds.reduce((r, v) => r.add(v), new Decimal(0)) }

    private async updateStage(scope: ContractStageScope, newScope: ContractStageScope) {
        if (this.expandedStage === scope.stage) {
            this.expandedStage = newScope.stage;
        }

        scope.stage = newScope.stage;
        scope.executionInfo = newScope.executionInfo;
        scope.acceptanceInfo = newScope.acceptanceInfo;
        scope.paymentInfo = newScope.paymentInfo;

        const ix = this.props.source.contract.newStages.findIndex(x => x.id === newScope.stage.id);
        if (ix !== -1) {
            this.props.source.contract.newStages.splice(ix, 1, newScope.stage);
        }

        const realIx = this.props.source.realContract.newStages.findIndex(x => x.id === newScope.stage.id);
        if (realIx !== -1) {
            this.props.source.realContract.newStages.splice(ix, 1, ContractStage.fromJson(newScope.stage.toJson()));
        }
    }

    public async registerStage(scope: ContractStageScope, isEis?: boolean) {
      const isCreating = (scope.stage.status === 'CREATING' || scope.stage.status === 'CREATING_FIRST');

      if (!scope.stage.completed
          && (
              (isCreating && scope.contractScope.contract.law == LawType.F223)
              || (scope.contractScope.contract.law == LawType.F44 && scope.stage.status === "FO_AGREED")
          )
      ) {
            const r = await EventBus.callModal(HintModal, {
                header: "Отправка этапа на регистрацию",
                text: "Внимание! Вы отправляете на регистрацию промежуточные сведения об исполнении этапа. Вы уверены, что хотите продолжить?"
            });

            if (!r.isOk) return;
        }

        const newScope = await Stages.register(this.props.source, scope.stage.id, scope.stage.completed, isEis ?? false);
        this.props.source.contract.waitingForStageRegistration = true;
        this.props.source.realContract.waitingForStageRegistration = true;
        this.updateStage(scope, newScope);
    }

    public async changeStage(scope: ContractStageScope) {
        const r = await EventBus.callModal(HintModal, {
            header: "Внесение изменений в этап исполнения",
            text: "Вы уверены, что хотите внести изменения в зарегистрированный этап контракта? " +
                "При внесении изменений все исполнения поставщиком, платежи и претензионные " +
                "работы перейдут в статус 'Редактирование' и потребуют повторной регистрации в ЕИС. " +
                "Для последующего исключения дублирования сведений об исполнении в рамках данного " +
                "этапа в ЕИС потребуется аннулировать все сведения об этапе перед отправкой на " +
                "повторную регистрацию сведений. Внести изменения в зарегистрированный этап?"
        });

        if (!r.isOk) return;

        const newScope = await Stages.makeChanges(this.props.source, scope.stage.id);
        this.updateStage(scope, newScope);
    }

    public async rollbackStage(scope: ContractStageScope) {
        const newScope = await Stages.rollback(this.props.source, scope.stage.id);
        this.updateStage(scope, newScope);
    }

    public async sendDissolutionToEis(scope: ContractStageScope, src: ContractStageDissolutionInfo) {
        const r = await StageDissolution.sendToEis(src);

        const ix = scope.dissolutionInfo.findIndex(x => x.id === r.id);
        scope.dissolutionInfo.splice(ix, 1, r);
    }
}
