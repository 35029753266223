import {AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {Decimal} from 'decimal.js';
import {DtoFinancialLimit, FinancialLimit} from "@/models/ComposedLots/FinancialLimit";
import { FinancialActionSource, FinancialSource } from "@/models";

interface LotPlannedPaymentMD {
    source: MDMap<FinancialLimit, DtoFinancialLimit>
    amount: Decimal
}

export type DtoLotPlannedPayment = AutoDto<LotPlannedPaymentMD>;
export type LotPlannedPayment = AutoModel<LotPlannedPaymentMD>;

export const LotPlannedPayment = {
    toDto(info: LotPlannedPayment): DtoLotPlannedPayment {
        return {
            source: FinancialLimit.toDto(info.source),
            amount: info.amount.toString(),
        };
    },
    fromDto(info: DtoLotPlannedPayment): LotPlannedPayment {
        return {
            source: FinancialLimit.fromDto(info.source),
            amount: new Decimal(info.amount),
        };
    }
};

interface LotPlannedPaymentTemplateMD {
    sourceId: string
    amount: Decimal
}

export type DtoLotPlannedPaymentTemplate = AutoDto<LotPlannedPaymentTemplateMD>;
export type LotPlannedPaymentTemplate = AutoModel<LotPlannedPaymentTemplateMD>;

export const LotPlannedPaymentTemplate = {
    toDto(info: LotPlannedPaymentTemplate): DtoLotPlannedPaymentTemplate {
        return {
            sourceId: info.sourceId,
            amount: info.amount.toString()
        };
    },
    fromDto(info: DtoLotPlannedPaymentTemplate): LotPlannedPaymentTemplate {
        return {
            sourceId: info.sourceId,
            amount: new Decimal(info.amount)
        };
    }
};

export const convertFromFinancialSource = (f: FinancialSource): FinancialLimit => ({
    ...f,
    price: f.total,
    plannedAmount: f.used,
    is223: (f as FinancialActionSource)?.law === "F223",
    kvfo: (f as FinancialActionSource)?.provisionCode,
    kosgu: (f as FinancialActionSource)?.govOperationClass,
    kvr: (f as FinancialActionSource)?.expenseKind
});
