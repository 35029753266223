





























import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import EventBus, { showModal } from "@/EventBus";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddSource from "./AddSource.vue";
import { Contract, ContractFinancialSource } from '../../models/Contracts';
import { SimpleRow } from '../../components/vue/form-table/FormFields';
import { IFieldProps } from '../../components/vue/form-table/FieldBase';
import { LawType } from '../../models/enums';
import { formatNumber } from '../../NumberFormatting';
import { ContractScope } from './ContractScope';
import ContractFinancialSources from '../../api/ContractFinancialSources';
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";
import {isConcludedEshopContract} from "@/form-declarations/contractStageActions";
import { getLotById } from "@/api/ComposedLots";
import {AddSourceModalSourcePropType} from "@/views/CreateContract/AddSource.vue";

@Component({ components: { DataTable } })
export default class Sources extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public isActionsLocked = isConcludedEshopContract(this.props.source.contract);

    public get headers(): ITableColumn<ContractFinancialSource>[] {
        return [
            {
                title: "№",
                getter: (n, i) => (i + 1).toString(),
                size: "auto",
            },
            {
                title: "Год бюджета",
                getter: n => n.source.year,
            },
            {
                title: "Источник финансирования",
                getter: n => n.source.typeString(),
            },
            {
                title: "​КБК",
                getter: n => n.source.code(),
            },
            {
                title: "Лицевой счёт",
                getter: n => n.account && n.account.accountNumber,
            },
            {
                title: "​Сумма, руб.",
                getter: n => formatNumber(n.amount),
            },
        ];
    }

    public createConfig(): IRowConfiguration<ContractFinancialSource> {
        const that = this;
        return {
            get useSlotForActionColumn() { return that.props.state === 'ENABLED' && !that.isActionsLocked }
        };
    }

    public config = this.createConfig();

    public get dataset() {
        return (this.props.source as ContractScope).financialSources;
    }

    public async addEditSource(src: ContractFinancialSource | null) {
        const isAggregated = this.props.source.realContract.isAggregated;
        const lot = await getLotById(this.props.source.contract.lot2020Id!);

        let source: AddSourceModalSourcePropType = { type: "empty", value: null };
        // #751, there is only one financial source if contract is based on aggregated lot
        if (isAggregated) {
            source = { type: "inherited", value: lot.plannedPayments };
        } else if (src) {
            source = { type: "default", value: src };
        } else {
            source = { type: "empty", value: null };
        }

        const props = {
            source,
            law: this.props.source.contract.law,
            accounts: this.props.source.realContract.customer.accounts,
            contractId: this.props.source.realContract.id,
            isAggregated,
        };
        const result = await EventBus.callModal<typeof props, ContractFinancialSource>(AddSource, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.dataset.push(r);
            } else {
                const ix = this.dataset.findIndex(x => x.id === r.id);
                this.dataset.splice(ix, 1, r);
            }
        }
    }
    public async addCloneSource(src: ContractFinancialSource | null) {
        const props = {
            source: src,
            law: this.props.source.contract.law,
            accounts: this.props.source.realContract.customer.accounts,
            contractId: this.props.source.realContract.id,
            isAggregated: this.props.source.realContract.isAggregated
        };
        const result = await EventBus.callModal<typeof props, ContractFinancialSource>(AddSource, props);
        if (result.isOk) {
            const r = result.getResult();
            this.dataset.push(r);
        }
    }

    public async deleteSource(src: ContractFinancialSource) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить финансирование?"});
        if (!confirmation)
            return;

        await ContractFinancialSources.delete(src);

        const ix = this.dataset.indexOf(src);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }
}
